import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 460px;
  background: #e8e4e1;
  position: relative;
  overflow: hidden;
  border-radius: 99999px;
  height: 4px;

  margin: 40px auto;
`;

const Progress = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  transform-origin: left;
  width: 100%;

  transform: ${props => `scaleX(${props.progress})`};

  transition: all 0.5s ease-in-out;

  background: #f2725a;
`;

export default ({ steps, step, ...props }) => {
  let containerRef = useRef();

  let [progress, setProgress] = useState(step / steps);
  let [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (step && steps) {
      setProgress(step / steps);
      setLoaded(true);
    } else {
      setLoaded(false);
    }
  }, [step]);
  if (!loaded) return null;
  return (
    <Container ref={containerRef}>
      <Progress progress={progress}></Progress>
    </Container>
  );
};
