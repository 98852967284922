import styled from 'styled-components';

export const VideoThumbnail = styled.div`
	position: relative;

	& > img {
		display: block;
		width: 100%;
	}

	& > div {
		position: absolute;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.3);
		cursor: pointer;

		opacity: 1;

		::before {
			content: '';
			position: absolute;
			border-left: ${(props) => `calc(${props.w}px / 4) solid #f1f1f1`};
			border-top: ${(props) => `calc(${props.h}px / 5) solid transparent`};
			border-bottom: ${(props) => `calc(${props.h}px / 5) solid transparent`};
			height: 0;
			width: 0;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`;
