import { print } from 'graphql';
import Client from 'shopify-buy';

import { questionnaireServerAxiosInstance, shopifyGraphQLAxiosInstance } from './axios';
import { STOREFRONT_API_KEY, STORE_DOMAIN } from '../config';
import { CREATE_CHECKOUT_MUTATION, UPDATE_CHECKOUT_ATTRIBUTES, ASSOCIATE_CHECKOUT_CUSTOMER, CREATE_ORDER } from './graphql/mutations';


const client = Client.buildClient({
	domain: STORE_DOMAIN,
	storefrontAccessToken: STOREFRONT_API_KEY,
});

export const getThirdRoutineRecovery = (selectedAreas) => {
	return selectedAreas.length === 1
		? selectedAreas.map((item) => {
				return item.lr ? `${item.name} (${item.lr})` : `${item.name}`;
		  })
		: selectedAreas
				.map((item) => {
					return item.lr ? `${item.name} (${item.lr})` : `${item.name}`;
				})
				.join(', ');
};

export const getPainDurationInSentence = (painDuration) => {
	switch (painDuration.id) {
		case 1:
			return 'less than 4 weeks';
		case 2:
			return 'between 4 and 12 weeks';
		case 3:
			return 'over 12 weeks';
		default:
			return '';
	}
};

export const getUsedTime = (painDuration) => {
	switch (painDuration.id) {
		case 1:
			return '5 days';
		case 2:
			return '10 days';
		case 3:
			return '2 weeks every month';
		default:
			return 'Every morning';
	}
};

export const getBodyPart = (data, prop = '') => {
	const selected = data.find((d) => d.step === 2).data.cards.find((c) => c.isSelected);
	return prop ? selected[prop] : selected;
};

export const getPainDuration = (data, prop = '') => {
	const selected = data.find((d) => d.step === 5).data.cards.find((c) => c.isSelected);
	return prop ? selected[prop] : selected;
};

export const getSelectedArea = (data, prop = '') => {
	const selected = data.find((d) => d.step === 3).data.selectedAreas;
	return prop ? selected[prop] : selected;
};

export const getPainScale = (data, prop = '') => {
	const dataForthStep = data.find((d) => d.step === 4).data;
	const selected = dataForthStep.levels.find((item) => item.isSelected);
	return prop ? selected[prop] : selected;
};

export const getPainCause = (data, prop = '') => {
	const dataSixthStep = data.find((d) => d.step === 6).data;
	const selected = dataSixthStep.cards.find((item) => item.isSelected);
	return prop ? selected[prop] : selected;
};

export const getUserInfo = (data) => {
	const dataSeventhStep = data.find((d) => d.step === 7).data;
	const name = dataSeventhStep.inputs[0].props.value;
	const email = dataSeventhStep.inputs[1].props.value;

	return { name, email };
};

export const getSummarySelectedAreas = ({ selectedAreas }) => {
	return selectedAreas.length === 1
		? selectedAreas.map((item) => {
				return item.lr ? `${item.name} (${item.lr})` : `${item.name}`;
		  })
		: selectedAreas
				.map((item) => {
					return item.lr ? `${item.name} (${item.lr})` : `${item.name}`;
				})
				.join(', ');
};

export const getCheckoutProducts = (data) => {
	const checkoutItems = data.find((item) => item.step === 9).data.columns[1].data;
	const checkoutProducts = checkoutItems.products;
	return checkoutProducts;
};

export const getCheckoutVideos = (data) => {
	const checkoutItems = data.find((item) => item.step === 9).data.columns[1].data;
	const checkoutVideos = checkoutItems.videos;
	return checkoutVideos;
};

export const getHashFromSelections = (data) => {
	const levelSelected = getPainScale(data);

	const painDurationSelected = getPainDuration(data);

	const selectedAreas = getSelectedArea(data);
	let selectedAreasIds = selectedAreas.map((item) => item.id);
	selectedAreasIds.sort();
	selectedAreasIds = selectedAreasIds.join(' ');

	const painCauseSelected = getPainCause(data);

	const mySelections = `${selectedAreasIds} ${levelSelected.id} ${painDurationSelected.id} ${painCauseSelected.id}`;

	return btoa(mySelections);
};

export const getTypeSelection = (data) => {
	const dataSecondStep = data.find((item) => item.step === 2).data;
	const selected = dataSecondStep.cards.find((item) => item.isSelected);
	return selected.id;
};

export const getRecommendItems = (data) => {
	const key = getHashFromSelections(data);
	return questionnaireServerAxiosInstance.get(`/recommendation/${key}`);
};

export const fetchAllRecommendProducts = async (recommendItems) => {
	try {
		const fetchProductsPromises = [];
		recommendItems.forEach((items) => {
			fetchProductsPromises.push(
				new Promise(async (resolve, reject) => {
					try {
						const response = await client.product.fetch(items.id);
						resolve(response);
					} catch (error) {
						console.error('error fetch product :>> ', error);
						reject(error);
					}
				})
			);
		});

		const response = await Promise.all(fetchProductsPromises);

		return response;
	} catch (error) {
		console.error('error :>> ', error);
	}
};

export const createCheckout = async (createCheckoutInput) => {
	try {
		const responseCreateCheckout = await shopifyGraphQLAxiosInstance({
			method: 'post',
			data: {
				query: print(CREATE_CHECKOUT_MUTATION),
				variables: {
					input: createCheckoutInput,
				},
			},
		});
		return responseCreateCheckout.data.data.checkoutCreate.checkout;
	} catch (error) {
		console.error('error :>> ', error);
		return null;
	}
};

export const updateCheckoutAttributes = async (checkoutId, customAttributes) => {
	try {
		const responseUpdateCheckout = await shopifyGraphQLAxiosInstance({
			method: 'post',
			data: {
				query: print(UPDATE_CHECKOUT_ATTRIBUTES),
				variables: {
					checkoutId,
					input: {
						customAttributes,
					},
				},
			},
		});

		return responseUpdateCheckout.data.data.checkoutCreate.checkout;
	} catch (error) {
		console.error('error :>> ', error);
	}
};

export const associateCheckoutWithCustomer = async (checkoutId, customerAccessToken) => {
	try {
		const response = await shopifyGraphQLAxiosInstance({
			method: 'post',
			data: {
				query: print(ASSOCIATE_CHECKOUT_CUSTOMER),
				variables: {
					checkoutId,
					customerAccessToken,
				},
			},
		});
		return response.data.data.checkoutCustomerAssociateV2.checkout;
	} catch (error) {
		console.error('error :>> ', error);
	}
};

export const createCheckoutHandler = async (products) => {
	try {
		const checkoutItems = products.map((item) => {
			return {
				quantity: item.quantity,
				variantId: item.variantId,
			};
		});

		const createCheckoutInput = {
			lineItems: checkoutItems,
		};

		const checkout = await createCheckout(createCheckoutInput);
		return checkout;
	} catch (error) {
		console.error('error :>> ', error);
	}
};

export const createOrder = async (masterData) => {
	try {
		const responseCreateOrder = await shopifyGraphQLAxiosInstance({
			method: 'post',
			data: {
				query: print(CREATE_ORDER),
				variables: {
					input: masterData
				}
			}
		});
		
		return responseCreateOrder.data;
	} catch (error) {
		console.log(error);
		return null;
	}
}

export const createOrderHandler = async (products) => {
	try {
		const orderItems = products.map((item) => {
			return {
				final_price: item.price*0.9,
				quantity: item.quantity,
				variantId: item.variantId,
			};
		});

		const createOrderInput = {
			lineItems: orderItems,
		};

		const order = await createOrder(createOrderInput);
		return order;
	} catch (error) {
		console.log(error)
	}
}
export const goToCartHandler = async (masterData,exercises) => {
	try {		
		const checkoutProducts = getCheckoutProducts(masterData);
		const checkout = await createCheckoutHandler(checkoutProducts);
		const customerAccessToken = localStorage.getItem('customerAccessToken');
		if (customerAccessToken) {
			await associateCheckoutWithCustomer(checkout.id, customerAccessToken);
		}
		const checkoutToken = checkout.webUrl.split('/checkouts/')[1].substring(0, 32);
		storeCheckoutToDB(masterData,exercises, checkoutToken);

		window.parent.location.href = checkout.webUrl.replace("painfixbody.myshopify.com", "painfixbody.com");
		
	} catch (error) {
		console.error('error create checkout :>> ', error);
	}
};

export const storeCheckoutToDB = async (masterData,exercises, checkoutToken) => {
	try {
		const type = getTypeSelection(masterData);
		const selectedAreas = getSelectedArea(masterData);
		const musclePart = getSummarySelectedAreas({ selectedAreas });
		const videos = getCheckoutVideos(masterData);
		const painLevel = getPainScale(masterData).id;

		const checkoutProducts = getCheckoutProducts(masterData);
		const thumbnails = checkoutProducts.map((item) => item.thumbnail);

		if (checkoutProducts.length > 0) {
			await questionnaireServerAxiosInstance.post(`/checkout`, {
				checkoutToken,
				painLevel,
				videos: JSON.stringify(exercises),
				type,
				musclePart,
				thumbnails: JSON.stringify(thumbnails),
			});
		}
	} catch (error) {
		console.error('Error store checkout : ', error);
	}
};
