import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

const initialUserState = {
  isChecked: false,
  isSignedIn: false,
  listShippingAddress: [],
  listBillingAddress: [],
  user: {},
  cartTotal: 0,
};

export default ({ children }) => {
  const [userState, setUserState] = useState(initialUserState);
  return (
    <UserContext.Provider value={{ userState, setUserState }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
