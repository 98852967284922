import React from 'react';
import styled from 'styled-components';
import { Title, Subtitle, Container } from '../questionnaire_welcome';
import Button from '../predefined-components/button';
import PainScale from './PainScale';

const CustomTitle = styled(Title)`
	font-size: 32px;
	line-height: 38px;
	margin-top: 50px;
`;

const CustomSubtitle = styled(Subtitle)`
	color: #459fa0;
	font-size: 18px;
	line-height: 21px;
	font-weight: 500;
`;

const PainFace = styled.img`
	margin-top: 30px;
`;

const PainScaleWrapper = styled.div`
	flex-wrap: wrap;
	margin-top: 50px;
	display: flex;
	width: 100%;
	max-width: 360px;
	justify-content: center;
	align-items: center;

	& > p {
		:first-of-type {
			justify-content: flex-start;
			order: 2;

			@media screen and (min-width: 768px) {
				justify-content: flex-end;
			}
		}

		@media screen and (min-width: 768px) {
			order: unset !important;
		}

		:last-of-type {
			order: 3;
			justify-content: flex-end;

			@media screen and (min-width: 768px) {
				justify-content: flex-start;
			}
		}
		display: flex;
		flex: 1;
		font-size: 18px;
		color: #4a4a4a;
		line-height: 21px;
		font-family: 'Zilla Slab', serif;
	}

	@media screen and (min-width: 768px) {
		flex-wrap: nowrap;
		max-width: 100%;
	}
`;

export default ({ subtitle, levels, setData, button, style, ...props }) => {
	const active = levels.find((l) => l.isSelected);
	return (
		<Container style={style}>
			<CustomTitle>How much does it hurt?</CustomTitle>
			<CustomSubtitle>{subtitle(active)}</CustomSubtitle>
			<PainFace src={active.icon} alt="questionnaire pain level face icon" />
			<PainScaleWrapper>
				<p>No Pain</p>
				<PainScale
					setScale={(id) =>
						setData({
							levels: levels.map((l) => ({
								...l,
								isSelected: l.id === id,
							})),
						})
					}
					levels={levels}
					active={active}
				></PainScale>
				<p>Debilitating Pain</p>
			</PainScaleWrapper>
			<Button {...button.props} style={button.style}>
				{button.text}
			</Button>
		</Container>
	);
};
