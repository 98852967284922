import React, { useRef, useEffect } from 'react';
import PainFix from './painfix';

import TooltipPortal from './components/tip-portal';
import ModalContextProvider from './context/modal';
import UserContextProvider from './context/user';
import TooltipContextProvider from './context/tooltip';

import './index.css';

export default () => {
	const tooltipRef = useRef();

	useEffect(() => {
		function getParentLocalStorage(message) {
			if (
				/^https:\/\/painfixbody\.myshopify\.com/.test(message.origin) ||
				/^https:\/\/shop\.painfixbody\.com/.test(message.origin) ||
				/^https:\/\/painfixbody\.com/.test(message.origin) 
			) {
				const data = JSON.parse(message.data);
				for (const key in data) {
					localStorage.setItem(key, data[key]);
				}
			}
		}

		window.addEventListener('message', getParentLocalStorage);

		return () => {
			window.removeEventListener('message', getParentLocalStorage);
		};
	}, []);

	return (
		<>
			<TooltipContextProvider>
				<ModalContextProvider>
					<UserContextProvider>
						<PainFix toolTipRef={tooltipRef} />
						<TooltipPortal ref={tooltipRef}></TooltipPortal>
					</UserContextProvider>
				</ModalContextProvider>
			</TooltipContextProvider>
		</>
	);
};
