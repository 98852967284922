import { useRef, useEffect, useState } from "react";

export function useTouchOutside(defaultState = false) {
  let ref = useRef();
  let [outside, setOutside] = useState(defaultState);

  const handleTouch = event => {
    if (ref.current.contains(event.target)) {
      setOutside(false);
      return;
    } else {
      setOutside(true);
    }
  };

  useEffect(() => {
    document.addEventListener("touchstart", handleTouch);
    return () => {
      document.removeEventListener("touchstart", handleTouch);
    };
  }, []);

  return [ref, outside];
}
