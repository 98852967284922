export const generateSixthStepState = () => ({
  step: 6,
  name: "Questionnaire Pain Cause",
  data: {
    cards: [
      {
        id: 1,
        title: "Gym / Sports Injury",
        isSelected: false,
      },
      {
        id: 2,
        title: "Repetitive strain / Overuse",
        isSelected: false,
      },
      {
        id: 3,
        title: "Poor posture",
        isSelected: false,
      },
      {
        id: 4,
        title: "Physical trauma (e.g. a fall or car accident)",
        isSelected: false,
      },
      {
        id: 5,
        title: "Arthritis",
        isSelected: false,
      },
      {
        id: 6,
        title: "Recurring injury",
        isSelected: false,
      },
      {
        id: 7,
        title: "Other",
        isSelected: false,
      },
      {
        id: 8,
        title: "I don’t know",
        isSelected: false,
      },
    ],
  },
});
