export const MODAL_CONSTANT = {
	TYPE_FULLPAGE: 'TYPE_FULLPAGE',
	TYPE_BODY: 'TYPE_BODY',

	MODAL_NONE: 'MODAL_NONE',
	MODAL_GENERAL_INFO: 'MODAL_GENERAL_INFO',
	MODAL_UPDATE_PAIN_LEVEL: 'MODAL_UPDATE_PAIN_LEVEL',
	MODAL_QUESTIONAIRE_RECOVERY_KIT: 'MODAL_QUESTIONAIRE_RECOVERY_KIT',
	MODAL_ORDER_CANCEL_SUBSCRIPTION: 'MODAL_ORDER_CANCEL_SUBSCRIPTION',
};
