import React from 'react';
import styled from 'styled-components';

export const CartItem = styled.div`
	background: white;
	display: grid;
	grid-template-columns: 72px 1fr;
	grid-template-rows: minmax(72px, 72px);

	:not(:last-of-type) {
		margin-bottom: 20px;
	}

	& > img {
		width: 100%;
		align-self: center;
	}
`;

export const CartItemContent = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content;
	grid-template-rows: max-content max-content;
	padding: 10px;
	align-content: space-between;

	h1 {
		white-space: wrap;
		overflow-x: hidden;
		text-overflow: ellipsis;
		font-family: 'Zilla Slab', serif;
		font-size: 18px;
		line-height: 22px;
		letter-spacing: 0.5px;
		color: #272822;
		font-weight: bold;
		align-self: flex-end;
	}

	img {
		cursor: pointer;
		height: 9px;
		transform: rotate(45deg);
		justify-self: flex-end;
	}
`;

export const TotalPrice = styled.span`
	font-size: 14px;
	line-height: 24px;
	color: #1e1e1e;
`;

const Input = styled.input`
	width: 30px;
	border: none;
	outline: none;
	display: inline-block;
	background: #faf7f5;
	margin: 0px 5px;
	text-align: center;
`;

const QtyChangerContainer = styled.div`
	margin: 0px 5px;
	display: inline-block;
	user-select: none;
`;

export const QtyChanger = ({ onChangeClick, productId, v, min, max, loading, ...props }) => {
	return (
		<QtyChangerContainer>
			<span style={{ cursor: 'pointer' }} onClick={() => onChangeClick(min && v - 1 < min ? min : v - 1)}>
				-
			</span>
			<Input
				onChange={(e) => {
					if (min - 1 < e.target.value && max + 1 > e.target.value) {
						onChangeClick(parseInt(e.target.value));
					}
				}}
				value={v}
				type="number"
			/>
			<span style={{ cursor: 'pointer' }} onClick={() => onChangeClick(max && v + 1 > max ? max : v + 1)}>
				+
			</span>
		</QtyChangerContainer>
	);
};
