import React, { useEffect } from 'react';
import styled from 'styled-components';
import { BaseModal } from '../BaseModal';
import Button from '../../components/predefined-components/button';

const Separator = styled.div`
	position: relative;
	margin: 30px 0px;
	height: 1px;
	background: #e7e7e7;

	::before {
		content: 'OR';
		color: #e7e7e7;
		position: absolute;
		background: white;
		padding: 0px 20px;

		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const Title = styled.p`
	font-size: 24px;
	/* font-family: "Zilla Slab", serif; */
	text-align: center;
	margin-bottom: 10px;
`;

const Container = styled(BaseModal)`
	top: 50%;
	transform: translate(-50%, -50%);
	max-width: 80% !important;
	min-width: unset !important;
	min-height: unset !important;
	max-height: unset !important;
	width: 100% !important;

	@media screen and (min-width: 768px) {
		max-width: 650px !important;
	}

	padding: 30px 40px;
`;

const Buttons = styled.div`
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	/* 
  & > * {
    flex: 1;
  } */

	${Separator} {
		flex: unset;
		::before {
			padding: 0px 10px;
		}
	}
`;

export default ({ text, buttons, body, separator, onClose, ...props }) => {
	useEffect(() => () => onClose(), []);
	return (
		<Container>
			<Title>{text}</Title>
			{body && <body.render></body.render>}
			<Buttons style={buttons.style}>
				<Button style={buttons.cancel.style} onClick={buttons.cancel.onClick}>
					{buttons.cancel.text}
				</Button>
				{separator && <Separator style={separator}></Separator>}
				<Button style={buttons.confirm.style} onClick={buttons.confirm.onClick}>
					{buttons.confirm.text}
				</Button>
			</Buttons>
		</Container>
	);
};
