export const generateSeventhStepState = ({ onChangeNameHandler, onChangeEmailHandler, name, email }) => {
	return {
		step: 7,
		name: 'Questionnaire Gather Info',
		data: {
			inputs: [
				{
					id: 1,
					style: {
						marginTop: 40,
					},
					props: {
						required: true,
						placeholder: 'First Name',
						value: name,
						// value: !isGuest ? userState.user.firstName : '',
					},
					updateChange: onChangeNameHandler,
				},
				{
					id: 2,
					style: {
						marginTop: 20,
					},
					props: {
						required: true,
						placeholder: 'E-mail',
						// value: !isGuest ? userState.user.userName : '',
						type: 'email',
						value: email,
					},
					updateChange: onChangeEmailHandler,
				},
			],
			button: {
				text: 'enter',
				props: {
					type: 'submit',
					top: 30,
				},
			},
		},
	};
};
