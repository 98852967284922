const API_URLS = {
	DEVELOPMENT: {
		API: 'http://localhost:8080',
	},
	PRODUCTION: {
		API: '/',
	},
};

export const API_URL = API_URLS[process.env.REACT_APP_ENV].API;

export const STORE_URL = 'https://painfixbody.myshopify.com';
export const STORE_DOMAIN = 'painfixbody.myshopify.com';
export const STOREFRONT_API_KEY = 'd088a78b2e36f687dafbb58438624589';
export const STOREFRONT_GRAPHQL_API_URL = `${STORE_URL}/api/2021-01/graphql.json`;
