import React from 'react';
import { MODAL_CONSTANT } from '../constant/modal';
import GeneralInfo from './general-info-modal';
import UpdatePainLevel from './update-pain-level';
import QuestionaireRecoveryKitModal from './questionair-recovery-kit';
import { useModalContext } from '../context/modal';

export default (props) => {
	const { modalState, setModalState } = useModalContext();
	const { name, data, onClose, onSuccess, onError } = modalState;
	const onCloseWrapper = async (data) => {
		onClose && onClose(data);
		setModalState({
			isActive: false,
			name: MODAL_CONSTANT.MODAL_NONE,
			data: null,
		});
	};

	const onErrorWrapper = async (message) => {
		await onCloseWrapper();
		await new Promise((resolve) => setTimeout(() => resolve(), 500));
		onError && onError(message);
	};

	const onSuccessWrapper = async (data) => {
		await onCloseWrapper();
		onSuccess && onSuccess(data);
	};
	let Modal = null;
	switch (name) {
		case MODAL_CONSTANT.MODAL_NONE:
			Modal = null;
			break;
		case MODAL_CONSTANT.MODAL_GENERAL_INFO:
			Modal = GeneralInfo;
			break;
		case MODAL_CONSTANT.MODAL_UPDATE_PAIN_LEVEL:
			Modal = UpdatePainLevel;
			break;
		case MODAL_CONSTANT.MODAL_QUESTIONAIRE_RECOVERY_KIT:
			Modal = QuestionaireRecoveryKitModal;
			break;
		default:
			Modal = null;
			break;
	}
	return Modal ? <Modal {...data} onSuccess={onSuccessWrapper} onError={onErrorWrapper} onClose={onCloseWrapper} /> : null;
};
