import React from 'react';

import { LEVELS } from '../Levels';

export const generateForthStepState = ({ onClickHandler }) => ({
	step: 4,
	name: 'Pain Scale',
	data: {
		subtitle: (data) => <>{`Level ${data.id}: ${data.name}`}</>,
		button: {
			style: {
				zIndex: 2,
			},
			props: {
				top: 30,
				onClick: onClickHandler,
			},
			text: 'select',
		},
		levels: LEVELS,
	},
});
