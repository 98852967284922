export const LEVELS = [
	{
		id: 1,
		name: 'Minor Pain',
		icon: 'https://i.imgur.com/7SW1ppe.png',
		isSelected: true,
	},
	{
		id: 2,
		name: 'Mild Pain',
		icon: 'https://i.imgur.com/0w9kUH8.png',
		isSelected: false,
	},
	{
		id: 3,
		name: 'Annoying Pain',
		icon: 'https://i.imgur.com/kRNQI2a.png',
		isSelected: false,
	},
	{
		id: 4,
		name: 'Moderate Pain',
		icon: 'https://i.imgur.com/Kzw7xOY.png',
		isSelected: false,
	},
	{
		id: 5,
		name: 'Distracting Pain',
		icon: 'https://i.imgur.com/CSclisY.png',
		isSelected: false,
	},
	{
		id: 6,
		name: 'Distressing Pain',
		icon: 'https://i.imgur.com/VvSvsBy.png',
		isSelected: false,
	},
	{
		id: 7,
		name: 'Sleep-interfering, Intense painn',
		icon: 'https://i.imgur.com/yosWBPp.png',
		isSelected: false,
	},
	{
		id: 8,
		name: 'Utterly horrible, Very Intense pain',
		icon: 'https://i.imgur.com/areu3oa.png',
		isSelected: false,
	},
	{
		id: 9,
		name: 'Unbearable, Severe pain',
		icon: 'https://i.imgur.com/q879DEt.png',
		isSelected: false,
	},
	{
		id: 10,
		name: 'Debilitating, Unable to Move pain',
		icon: 'https://i.imgur.com/BXVSgHb.png',
		isSelected: false,
	},
];
