import styled from 'styled-components';

export const BaseModal = styled.div`
	position: fixed;
	background: white;

	top: ${(props) => (props.top ? `${props.top}px` : '50px')};

	left: 50%;
	transform: translateX(-50%);

	z-index: 1001;
	width: auto;
	min-width: 70%;
	max-width: 80%;

	min-height: 40%;
	max-height: 80%;

	border-radius: 4px;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	align-items: center;
`;
