import React from 'react';
import styled from 'styled-components';
import { Title as BaseTitle, Container } from '../questionnaire_welcome';
import Button from '../predefined-components/button';

const Title = styled(BaseTitle)`
	font-size: 36px;
	line-height: 43px;
	margin-top: 60px;
`;

const Input = styled.input`
	padding: 20px 30px;

	border: 1px solid #dfdfdf;
	background: white;

	width: 100%;

	::placeholder {
		color: #9b9b9b;
	}

	outline: none;
	font-size: 18px;
	line-height: 21px;
	text-align: center;
`;

const Form = styled.form`
	max-width: 460px;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export default ({ nextStep, inputs, setData, button, ...props }) => {
	return (
		<Container>
			<Title>
				Great, thanks
				<br />
				Finally, what's your first name?
			</Title>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					if (e.target.checkValidity()) {
						nextStep();
					} else {
						return false;
					}
				}}
			>
				{inputs.map((i) => {
					return (
						<Input
							key={`questionnaire-gather-info-input-${i.id}`}
							onChange={(e) => {
								let newData = inputs.map((oi) => {
									return {
										...oi,
										props: {
											...oi.props,
											value: oi.id === i.id ? e.target.value : oi.props.value,
										},
									};
								});
								i.updateChange(e.target.value);
								setData({ inputs: newData });
							}}
							{...i.props}
							style={i.style}
						></Input>
					);
				})}
				<Button {...button.props} style={button.style}>
					{button.text}
				</Button>
			</Form>
		</Container>
	);
};
