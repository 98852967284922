export const generateSecondStepState = ({
  ArmFront,
  ArmBack,
  LegFront,
  LegBack,
  LowerFront,
  LowerBack,
  UpperFront,
  UpperBack,
}) => ({
  step: 2,
  name: "Questionnaire Body Part Selection",
  data: {
    cards: [
      {
        id: 1,
        title: "Upper Body",
        body: "Your neck, shoulders, chest or upper back.",
        param: "upper-body",
        svg: {
          front: {
            id: "upper-front-svg",
            Front: UpperFront,
          },
          back: {
            id: "upper-back-svg",
            Back: UpperBack,
          },
        },
        isSelected: false,
      },
      {
        id: 2,
        title: "Lower Body",
        body: "Your lower back, hips, glutes.",
        param: "lower-body",
        svg: {
          front: {
            id: "lower-front-svg",
            Front: LowerFront,
          },
          back: {
            id: "lower-back-svg",
            Back: LowerBack,
          },
        },
        isSelected: false,
      },
      {
        id: 3,
        title: "Arms",
        body: "Your upper arms, elbows, wrists or hands.",
        param: "arm",
        svg: {
          front: {
            id: "arm-front-svg",
            Front: ArmFront,
          },
          back: {
            id: "arm-back-svg",
            Back: ArmBack,
          },
        },
        isSelected: false,
      },
      {
        id: 4,
        title: "Legs",
        body: "Your thighs, knees, ankles or feet.",
        param: "leg",
        svg: {
          front: {
            id: "leg-front-svg",
            Front: LegFront,
          },
          back: {
            id: "leg-back-svg",
            Back: LegBack,
          },
        },
        isSelected: false,
      },
    ],
  },
});
