import { STOREFRONT_API_KEY, API_URL, STOREFRONT_GRAPHQL_API_URL } from '../../config';
import axios from 'axios';

export const shopifyGraphQLAxiosInstance = axios.create({
	headers: {
		'Content-Type': 'application/json',
		'X-Shopify-Storefront-Access-Token': STOREFRONT_API_KEY,
	},
	baseURL: STOREFRONT_GRAPHQL_API_URL,
});

export const questionnaireServerAxiosInstance = axios.create({
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});
