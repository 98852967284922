import React from "react";
import styled from "styled-components";
import { BaseModal } from "../BaseModal";
import Button from "../../components/predefined-components/button";

const Container = styled(BaseModal)`
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 80% !important;
  min-width: unset !important;
  min-height: unset !important;
  max-height: unset !important;

  @media screen and (min-width: 768px) {
    max-width: 450px !important;
  }

  padding: 30px 40px;

  p {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
  }

  span {
    font-size: 16px;
    margin-bottom: 30px;
    text-align: center;
  }
`;

export default ({ text, subtext = "", button, onClose, ...props }) => {
  return (
    <Container>
      <p>{text}</p>
      {subtext && <span>{subtext}</span>}
      <Button onClick={onClose}>{button.text}</Button>
    </Container>
  );
};
