import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	margin-top: 40px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	flex-direction: column;

	@media screen and (min-width: 768px) {
		flex-direction: row;
		align-items: unset;
	}
`;

const Column = styled.div`
	display: flex;

	flex-direction: column;
	align-items: center;

	max-width: 300px;
	width: 100%;

	:not(:first-of-type) {
		margin-top: 80px;
	}

	@media screen and (min-width: 768px) {
		margin: 0px !important;
	}
`;

const ColumnHeader = styled.p`
	font-size: 20px;
	line-height: 20px;
	color: #272822;
	font-weight: 500;

	font-family: 'Zilla Slab', serif;
`;

const ColumnBody = styled.div`
	width: 100%;
	flex: 1;
`;

const ColumnFooter = styled.div``;

export default ({ columns, setData, masterData, reset, ...props }) => {
	const onProductQuantityChanged = (id, quantity) => {
		setData({
			columns: columns.map((c) => {
				if (c.id === 1) return c;
				return {
					...c,
					data: {
						...c.data,
						products: c.data.products.map((p) => ({
							...p,
							quantity: p.id === id ? quantity : p.quantity,
						})),
					},
				};
			}),
		});
	};
	const firstName = masterData.find((d) => d.step === 7).data.inputs.find((i) => i.id === 1).props.value;

	return (
		<Container>
			{columns.map((c) => {
				return (
					<Column key={`questionnaire_your-routine-${c.id}`}>
						<ColumnHeader>{c.title(firstName)}</ColumnHeader>
						{c.body(
							{
								ColumnBody,
								onProductQuantityChanged,
								...c.data,
								...c.bodyComponents,
							},
							masterData
						)}
						{c.footer({
							ColumnFooter,
							...c.data,
							...c.footerComponents,
							masterData,
							reset,
						})}
					</Column>
				);
			})}
		</Container>
	);
};
