export const generateFirstStepState = ({ onClickHandler }) => ({
	step: 1,
	name: 'Questionnaire Welcome',
	data: {
		button: {
			text: 'get started',
			props: {
				top: 40,
				onClick: () => {
					onClickHandler();
				},
			},
		},
	},
});
