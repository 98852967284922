import gql from 'graphql-tag';

export const CREATE_CHECKOUT_MUTATION = gql`
	mutation($input: CheckoutCreateInput!) {
		checkoutCreate(input: $input) {
			checkout {
				id
				order {
					id
				}
				webUrl
			}
			checkoutUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const CREATE_ORDER = gql`
	mutation draftOrderCreate($input: DraftOrderInput!) {
		draftOrderCreate(input: $input) {
		draftOrder {
			id
		}
		userErrors {
			field
			message
		}
		}
	}
`

export const UPDATE_CHECKOUT_ATTRIBUTES = gql`
	mutation($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
		checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
			checkout {
				id
				webUrl
			}
			checkoutUserErrors {
				code
				field
				message
			}
		}
	}
`;

export const ASSOCIATE_CHECKOUT_CUSTOMER = gql`
	mutation($checkoutId: ID!, $customerAccessToken: String!) {
		checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
			checkout {
				id
				webUrl
			}
			checkoutUserErrors {
				code
				field
				message
			}
			customer {
				id
			}
		}
	}
`;

