import React, { createContext, useContext, useState } from 'react';

const TooltipContext = createContext();

const initialTooltipState = {
	ref: null,
	imgRef: null,
	show: false,
};

export default ({ children }) => {
	const [tooltipState, set] = useState(initialTooltipState);

	const setTooltipState = (newState) => {
		set({ ...tooltipState, ...newState });
	};
	return <TooltipContext.Provider value={{ tooltipState, setTooltipState }}>{children}</TooltipContext.Provider>;
};

export const useTooltipContext = () => useContext(TooltipContext);
