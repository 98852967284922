import React , {useEffect,useState} from 'react';
import styled from 'styled-components';
import intersection from 'lodash/intersection';

import { Container, WelcomeImg as BaseImg, Title as BaseTitle, Subtitle as BaseSubtitle } from '../questionnaire_welcome';
import Button from '../predefined-components/button';
import { getPainDuration, getSelectedArea, getRecommendItems } from '../../utils';
import { VIDEO_PLAYLISTS } from '../../constant';

const Title = styled(BaseTitle)`
	font-size: 36px;
	line-height: 43px;
	text-align: center;
	margin-top: 0px;

	max-width: 300px;

	@media screen and (min-width: 768px) {
		max-width: auto;
	}
`;

const Subtitle = styled(BaseSubtitle)`
	font-size: 16px;
	line-height: 22px;
	text-align: unset;
	max-width: 630px;
`;

const Img = styled(BaseImg)`
	margin-top: 30px;
`;
const ACUTE_RECOVERY_KIT_ITEMS = [
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMzcxODM1NjgwNjU=',
		image: 'https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix_Product_GelTube_157-2.jpg?v=1611546938',
		quantity: 2,
		title: 'Relief Gel',
		variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzcwOTYwMzM3MzI0OQ==',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMzcxNjg4ODgwMDE=',
		image: 'https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix_Product_PatchBox_116.jpg?v=1611546919',
		quantity: 2,
		title: 'Restore Patch',
		variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzcwOTUxMzkxNjYwOQ==',
	},
];
const CHRONIC_RECOVERY_KIT_ITEMS = [
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMzcxODM1NjgwNjU=',
		image: 'https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix_Product_GelTube_157-2.jpg?v=1611546938',
		quantity: 2,
		title: 'Relief Gel',
		variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzcwOTYwMzM3MzI0OQ==',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMzcxNjg4ODgwMDE=',
		image: 'https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix_Product_PatchBox_116.jpg?v=1611546919',
		quantity: 4,
		title: 'Restore Patch',
		variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzcwOTUxMzkxNjYwOQ==',
	},
];


export default ({ masterData, subtitle, button, recommendItems, ...props }) => {


	const getSummaryProducts = (painDurationId, selectedAreas) => {
		const numberOfSelectedAreas = selectedAreas.length;

		let summary = '';
		switch (painDurationId) {
			case 1:
				if(numberOfSelectedAreas <=3) {
					ACUTE_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
				};
				if(numberOfSelectedAreas > 3 ) {
					CHRONIC_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
					};
			case 2:
				if(numberOfSelectedAreas <=3) {
					CHRONIC_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
					};
				if(numberOfSelectedAreas > 3 && numberOfSelectedAreas <7 ) {
					CHRONIC_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity*2} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
					};
				if(numberOfSelectedAreas >=7 ) {
					CHRONIC_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity*3} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
					};	
			case 3:
				if(numberOfSelectedAreas <=3) {
					CHRONIC_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
					};
				if(numberOfSelectedAreas > 3 && numberOfSelectedAreas <7 ) {
					CHRONIC_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity*2} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
					};
				if(numberOfSelectedAreas >=7 ) {
					CHRONIC_RECOVERY_KIT_ITEMS.forEach((item) => {
						summary += `${item.quantity*3} ${item.title}, `;
					});
					summary = summary.substr(0, summary.length - 2);
					return summary;
					};	
		}
		
	};
	
	const getInstruction = (painDurationId, selectedAreas) => {
		const numberOfSelectedAreas = selectedAreas.length;

		switch (painDurationId) {
			case 1:
				return (
					<>
						<strong>5-day</strong> supply of Restore Patches for each problem area selected (for a total of {numberOfSelectedAreas} Restore
						Patch box sets, each containing 5 individual patches).
					</>
				);
			case 2:
				return (
					<>
						<strong>10-day</strong> supply of Restore Patches for each problem area selected (for a total of {numberOfSelectedAreas * 2}{' '}
						Restore Patch box sets, each containing 5 individual patches).
					</>
				);
			case 3:
				return (
					<>
						<strong>10-day</strong> supply of Restore Patches for each problem area selected (for a total of {numberOfSelectedAreas * 2}{' '}
						Restore Patch box sets, each containing 5 individual patches). You can cancel your monthly subscription anytime or when you’re
						happy with the improvement.
					</>
				);
		}
	};

	const getExercises = (selectedAreas) => {
		let exercises = VIDEO_PLAYLISTS.filter((vp) => {
			return (
				intersection(
					vp.test,
					selectedAreas.map((sa) => sa.id)
				).length > 0
			);
		}).map((vp) => vp.playlists_name);
		return exercises;
	};

	const recommendKit = (painDurationId, selectedAreas) => {
		const numberOfSelectedAreas = selectedAreas.length;

		switch (painDurationId) {
			case 1:
				if(numberOfSelectedAreas <=3) {
				return (
					<strong>
					1 PainFix Acute Recovery Kit 		
					</strong>
				)
				};
				if(numberOfSelectedAreas > 3) {
					return (
						<strong>
						1 PainFix Chronic Recovery Kits 		
						</strong>
					)
					};
			case 2:
				if(numberOfSelectedAreas <=3) {
					return (
						<strong>
						  1 PainFix Chronic Recovery Kit 	
						</strong>
					)
					};
				if(numberOfSelectedAreas > 3 && numberOfSelectedAreas <=6 ) {
					return (
						<strong>
						 2 PainFix Chronic Recovery Kits 		
						</strong>
					)
					};
				if(numberOfSelectedAreas > 6 ) {
					return (
						<strong>
						 3 PainFix Chronic Recovery Kits		
						</strong>
					)
					};	
			case 3:
				if(numberOfSelectedAreas <=3) {
					return (
						<strong>
						  1 PainFix Chronic Recovery Kit 	
						</strong>
					)
					};
				if(numberOfSelectedAreas > 3 && numberOfSelectedAreas <=6 ) {
					return (
						<strong>
						 2 PainFix Chronic Recovery Kits 		
						</strong>
					)
					};
				if(numberOfSelectedAreas > 6 ) {
					return (
						<strong>
						 3 PainFix Chronic Recovery Kits 	
						</strong>
					)
					};	
		}
	};
	const subTitle = () => {
		const { summarySelectedAreas, summaryPainDuration } = subtitle(masterData);
		const selectedAreas = getSelectedArea(masterData);
		const painDuration = getPainDuration(masterData);
		const exercises = getExercises(selectedAreas);
		const items =  recommendKit(painDuration.id,selectedAreas)

		return (
			<>
				Since the issue in your <strong>{summarySelectedAreas}</strong> is {summaryPainDuration}, we recommend {items}  {' '} 
				 and our {' '}
				 { exercises.map((el,i) => (
					 <strong key={el}>{el}{i != (exercises.length-1) ? ', ' : ''}</strong>
				 )) }
				  .
				<br></br>
				<br></br>
				The Painfix Recovery Kit is a <strong>3-step program</strong>  to help unlock your body's healing abilities and accelerate recovery.
				<br></br>
				<br></br>
				Step 1: Massage with Relief Gel 
				<br></br>
				 Step 2: Apply Restore Patch for up to 15 hours 
				 <br></br>
				  Step 3: Perform targeted therapeutic exercises.
				  <br></br>
				  <br></br>
				  <small>
				* Each PainFix Restore Patch box contains 5 individual patches for five days application to your problem area.</small>
			
			</>
		);
	};
	

	return (
		<Container>
			<Img src="https://i.imgur.com/eFHGgsy.png" alt={'questionnaire recommendation image'}></Img>
			<Title>Our Recommendation</Title>
			<Subtitle>{subTitle()}</Subtitle>
			<Button style={button.style} {...button.props}>
				{button.text}
			</Button>
		</Container>
	);
};
