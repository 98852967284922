import { VIDEO_TYPE } from './VideoType';

export const VIDEO_PLAYLISTS = [
	{
		id: 1,
		test: ['neck-front-right', 'neck-front-left', 'neck-back-right', 'neck-back-left', 'cervical-spine'],
		playlists_name: 'Strong Neck Exercises',
		sub_playlists:[
			{
			playlist_name: 'Strong Neck - Relief Exercises',
			thumbnail: 'https://i.vimeocdn.com/video/823454784.jpg',
			playlist_urls: [			
				{
					// name: "Neck Roll - Strong Neck - Relief Exercises",
					name: 'Neck Roll - Relief Exercises',
					url: 'https://vimeo.com/367174113/3699e435e4',
					thumbnail: 'https://i.vimeocdn.com/video/823443435.jpg',
					iframe: `<iframe src="https://player.vimeo.com/video/367174113" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
					type: VIDEO_TYPE.RELIEF,
					id: 367174113,
				},
				{
					// name: "Gravity Traction Neck Stretch - Strong Neck, Relief Exercise",
					name: 'Gravity Traction Neck Stretch - Relief Exercise',
					url: 'https://vimeo.com/376987906/0fd5e7c2a7',
					thumbnail: 'https://i.vimeocdn.com/video/836263529.jpg',
					iframe: `<iframe src="https://player.vimeo.com/video/376987906" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
					type: VIDEO_TYPE.RELIEF,
					id: 376987906,
				},
				{
					// name: "Chin to Shoulder Stretch - Strong Neck, Relief Exercise",
					name: 'Chin to Shoulder Stretch - Relief Exercise',
					url: 'https://vimeo.com/367174466/dcd778d14d',
					thumbnail: 'https://i.vimeocdn.com/video/823443848.jpg',
					iframe: `<iframe src="https://player.vimeo.com/video/367174511" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
					type: VIDEO_TYPE.RELIEF,
					id: 367174511,
				},
				{
					// name: "Side Flexion - Strong Neck, Relief Exercise",
					name: 'Side Flexion - Relief Exercise',
					url: 'https://vimeo.com/367174113/3699e435e4',
					thumbnail: 'https://i.vimeocdn.com/video/823443502.jpg',
					iframe: `<iframe src="https://player.vimeo.com/video/367174466" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
					type: VIDEO_TYPE.RELIEF,
					id: 367174466,
				},
				{
					// name: "Chin to Chest and Back Stretch - Strong Neck, Relief Exercise",
					name: 'Chin to Chest and Back Stretch - Relief Exercise',
					url: 'https://vimeo.com/367174209/5ee03c29ec',
					thumbnail: 'https://i.vimeocdn.com/video/823443435.jpg',
					iframe: `<iframe src="https://player.vimeo.com/video/367174209" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
					type: VIDEO_TYPE.RELIEF,
					id: 367174209,
				},				
				],
			},
			{
			playlist_name: 'Strong Neck - Strength Exercises',
			thumbnail: 'https://i.vimeocdn.com/video/823443435.jpg',
			playlist_urls: [
					{
						// name: "Isometric Neck Strengthening - Strong Neck, Strength Exercises",
						name: 'Isometric Neck Strengthening - Strength Exercises',
						url: 'https://vimeo.com/367183026/8cceb8c65f',
						thumbnail: 'https://i.vimeocdn.com/video/823454784.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367183026" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367183026,
					},
					{
						// name: "Y-Prone Neck Strengthening - Strong Neck, Strength Exercise",
						name: 'Y-Prone Neck Strengthening - Strength Exercise',
						url: 'https://vimeo.com/367183098/e699d1a3d9',
						thumbnail: 'https://i.vimeocdn.com/video/823454927.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/379693599" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 379693599,
					},
					{
						// name: "Isotonic Neck Strengthening - Strong Neck, Strength Exercise",
						name: 'Isotonic Neck Strengthening - Strength Exercise',
						url: 'https://vimeo.com/379693599/8964277156',
						thumbnail: 'https://i.vimeocdn.com/video/839808670.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367183098" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367183098,
					},
				],
				}
		]
	
	},
	{
		id: 2,
		test: [
			'shoulder-back-left',
			'shoulder-back-right',
			'shoulder-front-left',
			'shoulder-front-right',
			'lower-trapezius-left',
			'lower-trapezius-right',
			'upper-trapezius-left',
			'upper-trapezius-right',
			'shoulder-blade-muscles-left',
			'shoulder-blade-muscles-right',
			'thoracic-spine',
			'chest-right',
			'chest-left',
		],
		playlists_name: 'Strong Shoulders & Upper Back - Front Exercises',
		sub_playlists:[
			{
				playlist_name: 'Strong Shoulders & Upper Back - Relief Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823455869.jpg',
				playlist_urls: [
					{
						// name: "Shoulder Pendulum - Strong Shoulders & Upper Back, Relief Exercise",
						name: 'Shoulder Pendulum - Relief Exercise',
						url: 'https://vimeo.com/367183828/f5bda3d318',
						thumbnail: 'https://i.vimeocdn.com/video/823455869.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367183828" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367183828,
					},
					{
						// name: "Chest Expansion - Strong Shoulders & Upper Back, Relief Exercise",
						name: 'Chest Expansion - Relief Exercise',
						url: 'https://vimeo.com/367184093/cdded4fe25',
						thumbnail: 'https://i.vimeocdn.com/video/823456176.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367184093" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367184093,
					},
					{
						// name: "Shoulder Stretch - Strong Shoulders & Upper Back, Relief Exercise",
						name: 'Shoulder Stretch - Relief Exercise',
						url: 'https://vimeo.com/367184003/2698a47665',
						thumbnail: 'https://i.vimeocdn.com/video/823456046.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367184003" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367184003,
					},					
				],
			},
			{
				playlist_name: 'Strong Shoulders & Upper Back - Strength 1 Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/826826414.jpg',
				playlist_urls: [													
					{
						// name: "Hand To Wall Stretch - Strong Shoulders & Upper Back, Strength 1 Exercise",
						name: 'Hand To Wall Stretch - Strength 1 Exercise',
						url: 'https://vimeo.com/367185208/80c01aa606',
						thumbnail: 'https://i.vimeocdn.com/video/826826414.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367185208" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367185208,
					},
					{
						// name: "Shoulder Squeeze - Strong Shoulders & Upper Back, Strength 1 Exercise",
						name: 'Shoulder Squeeze - Strength 1 Exercise',
						url: 'https://vimeo.com/367185950/c5a19c7770',
						thumbnail: 'https://i.vimeocdn.com/video/823458443.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367185950" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367185950,
					},
					{
						// name: "Prone Push Half Body  - Strong Shoulders & Upper Back, Strength 1 Exercise",
						name: 'Prone Push Half Body - Strength 1 Exercise',
						url: 'https://vimeo.com/367185896/94ec04581a',
						thumbnail: 'https://i.vimeocdn.com/video/823458363.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367185896" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367185896,
					},
					{
						// name: "Prone Push Back  - Strong Shoulders & Upper Back, Strength 1 Exercise",
						name: 'Prone Push Back - Strength 1 Exercise',
						url: 'https://vimeo.com/367185858/1ce0109ece',
						thumbnail: 'https://i.vimeocdn.com/video/823458341.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367185858" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367185858,
					},
					{
						// name: "Sitting Tricep Stretch - Strong Shoulders & Upper Back, Strength 1 Exercise",
						name: 'Sitting Tricep Stretch - Strength 1 Exercise',
						url: 'https://vimeo.com/367185808/d81d454033',
						thumbnail: 'https://i.vimeocdn.com/video/823458274.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367185808" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367185808,
					},
				],
			},
			{
				playlist_name: 'Strong Shoulders & Upper Back - Strength 2 Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/826826269.jpg',
				playlist_urls: [				
					{
						// name: "Overhead Press - Strong Shoulders & Upper Back, Strength 2 Exercise",
						name: 'Overhead Press - Strength 2 Exercise',
						url: 'https://vimeo.com/367186136/8c2f9b7d94',
						thumbnail: 'https://i.vimeocdn.com/video/826826269.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367186136" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367186136,
					},
					{
						// name: "Shoulder Rowing - Strong Shoulders & Upper Back, Strength 2 Exercise",
						name: 'Shoulder Rowing - Strength 2 Exercise',
						url: 'https://vimeo.com/376987671/2fce771daf',
						thumbnail: 'https://i.vimeocdn.com/video/836262782.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376987671" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376987671,
					},
					{
						// name: "Forward Bench Stretch - Strong Shoulders & Upper Back, Strength 2 Exercise",
						name: 'Forward Bench Stretch - Strength 2 Exercise',
						url: 'https://vimeo.com/367186218/a43abf7141',
						thumbnail: 'https://i.vimeocdn.com/video/826826094.jpg',
						ifarme: `<iframe src="https://player.vimeo.com/video/367186218" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367186218,
					},
					{
						// name: "Lateral Raise - Strong Shoulders & Upper Back, Strength 2 Exercise",
						name: 'Lateral Raise - Strength 2 Exercise',
						url: 'https://vimeo.com/367186186/72264e9ae5',
						thumbnail: 'https://i.vimeocdn.com/video/823458824.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367186186" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367186186,
					},
					{
						// name: "Internal & External Shoulder Rotations - Strong Shoulders & Upper Back, Strength 2 Exercise",
						name: 'Internal & External Shoulder Rotations - Strength 2 Exercise',
						url: 'https://vimeo.com/367185328/bfcb0e06b5',
						thumbnail: 'https://i.vimeocdn.com/video/823459099.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367185328" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367185328,
					},
				],
			}
		],
		
	},
	{
		id: 3,
		test: [
			'bicep-left',
			'bicep-right',
			'forearm-front-left',
			'forearm-front-right',
			'elbow-right',
			'elbow-left',
			'forearm-back-left',
			'forearm-back-right',
			'triceps-left',
			'triceps-right',
		],
		playlists_name: 'Strong Elbows Exercises',
		sub_playlists: [
			{
				playlist_name: 'Strong Elbows - Relief Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823459655.jpg',
				playlist_urls: [					
					{
						// name: "Elbow Bend - Strong Elbows, Relief Exercise",
						name: 'Elbow Bend - Relief Exercise',
						url: 'https://vimeo.com/367186838/0c8cfb92a9',
						thumbnail: 'https://i.vimeocdn.com/video/823459655.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367186838" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367186838,
					},
					{
						// name: "Forearm & Wrist Turning - Strong Elbows, Relief Exercise",
						name: 'Forearm & Wrist Turning - Relief Exercise',
						url: 'https://vimeo.com/367186993/7dcbd6719d',
						thumbnail: 'https://i.vimeocdn.com/video/823459836.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367186993" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367186993,
					},
					{
						// name: "Forearm Stretch - Strong Elbows, Relief Exercise",
						name: 'Forearm Stretch - Relief Exercise',
						url: 'https://vimeo.com/367186942/bb95f7f5d4',
						thumbnail: 'https://i.vimeocdn.com/video/823459778.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367186942" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367186942,
					},
					{
						// name: "Elbow Straightening - Strong Elbows, Relief Exercise",
						name: 'Elbow Straightening - Relief Exercise',
						url: 'https://vimeo.com/367186910/f420058149',
						thumbnail: 'https://i.vimeocdn.com/video/823459728.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367186910" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367186910,
					},	
				],
			},
			{
				playlist_name: 'Strong Elbows - Strength Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823460112.jpg',
				playlist_urls: [			
					{
						// name: "Bicep Stretch - Strong Elbows, Strength Exercise",
						name: 'Bicep Stretch - Strength Exercise',
						url: 'https://vimeo.com/367187111/324b2e675f',
						thumbnail: 'https://i.vimeocdn.com/video/823460112.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367187111" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367187111,
					},
					{
						// name: "Pronation & Supination With Weights - Strong Elbows, Strength Exercise",
						name: 'Pronation & Supination With Weights - Strength Exercise',
						url: 'https://vimeo.com/367187440/a7da067816',
						thumbnail: 'https://i.vimeocdn.com/video/823460465.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367187440" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367187440,
					},
					{
						// name: "Tricep Stretch - Strong Elbows, Strength Exercise",
						name: 'Tricep Stretch - Strength Exercise',
						url: 'https://vimeo.com/367187198/53fb666d53',
						thumbnail: 'https://i.vimeocdn.com/video/823460134.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367187198" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367187198,
					},
				],
			}
		]
	
	},
	{
		id: 4,
		test: ['wrist-left', 'wrist-right'],
		playlists_name: 'Strong Wrist Exercises',
		sub_playlists: [
			{
				playlist_name : 'Strong Wrist - Relief Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823460631.jpg',
				playlist_urls: [		
					{
						// name: "Prayer Hands to Chest - Strong Wrist, Relief Exercise",
						name: 'Prayer Hands to Chest - Relief Exercise',
						url: 'https://vimeo.com/367187564/0732aebf37',
						thumbnail: 'https://i.vimeocdn.com/video/823460631.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367187564" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367187564,
					},
					{
						// name: "All Fours (Dynamic Wrist Stretch) - Strong Wrist, Relief Exercise",
						name: 'All Fours (Dynamic Wrist Stretch) - Relief Exercise',
						url: 'https://vimeo.com/367187913/824c4b5c7b',
						thumbnail: 'https://i.vimeocdn.com/video/823461013.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367187913" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367187913,
					},
					{
						// name: "Wrist Rotation - Strong Wrist, Relief Exercise",
						name: 'Wrist Rotation - Relief Exercise',
						url: 'https://vimeo.com/367187861/fa7c4bba86',
						thumbnail: 'https://i.vimeocdn.com/video/823460948.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367187861" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367187861,
					},
					{
						// name: "90 Degree Wrist Stretch - Strong Wrist, Relief Exercise",
						name: '90 Degree Wrist Stretch - Relief Exercise',
						url: 'https://vimeo.com/367187828/e2882138c1',
						thumbnail: 'https://i.vimeocdn.com/video/823460902.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367187828" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367187828,
					},						
				],
			},
			{
				playlist_name: 'Strong Wrist - Strength Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823462289.jpg',
				playlist_urls: [										
					{
						// name: "Wrist Flexion and Shoulder Rotation - Strong Wrist, Strength Exercise",
						name: 'Wrist Flexion and Shoulder Rotation - Strength Exercise',
						url: 'https://vimeo.com/367188913/627657bddb',
						thumbnail: 'https://i.vimeocdn.com/video/823462289.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367188913" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367188913,
					},
					{
						// name: "Wrist Extension - Strong Wrist, Strength Exercise",
						name: 'Wrist Extension - Strength Exercise',
						url: 'https://vimeo.com/367189028/8d5ad7fde3',
						thumbnail: 'https://i.vimeocdn.com/video/823462487.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189028" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367189028,
					},
					{
						// name: "Wrist Flexion With Dumbbell - Strong Wrist, Strength Exercise",
						name: 'Wrist Flexion With Dumbbell - Strength Exercise',
						url: 'https://vimeo.com/367188967/8e754f17b2',
						thumbnail: 'https://i.vimeocdn.com/video/823462374.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367188967" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367188967,
					},
				],
			}
		],

		
	},
	{
		id: 5,
		test: [
			'hip-left',
			'hip-right',
			'mid-back-left',
			'mid-back-right',
			'glute-left',
			'glute-right',
			'lower-back-left',
			'lower-back-right',
			'lumbar-spine',
			'sacrum',
			'abdominal',
			'trunk-left',
			'trunk-right',
			'groin-left',
			'groin-right',
		],
		playlists_name: 'Strong Lower Back & Hips Exercises',
		sub_playlists: [
			{
				playlist_name: 'Strong Lower Back & Hips - Relief Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823462822.jpg',
				playlist_urls: [									
					{
						// name: "Pelvic Tilt - Strong Lower Back & Hips, Relief Exercise",
						name: 'Pelvic Tilt - Relief Exercise',
						url: 'https://vimeo.com/367189275/b933d6da5e',
						thumbnail: 'https://i.vimeocdn.com/video/823462822.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189275" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367189275,
					},
					{
						// name: "Cat & Cow - Strong Lower Back & Hips, Relief Exercise",
						name: 'Cat & Cow - Relief Exercise',
						url: 'https://vimeo.com/367189478/7bf80aa1c8',
						thumbnail: 'https://i.vimeocdn.com/video/823463114.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189478" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367189478,
					},
					{
						// name: "Lying Knee Twist - Strong Lower Back & Hips, Relief Exercise",
						name: 'Lying Knee Twist - Relief Exercise',
						url: 'https://vimeo.com/367189440/dd311e1be2',
						thumbnail: 'https://i.vimeocdn.com/video/823463021.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189440" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367189440,
					},
					{
						// name: "Bridging - Strong Lower Back & Hips, Relief Exercise",
						name: 'Bridging - Relief Exercise',
						url: 'https://vimeo.com/367189399/2c577378a8',
						thumbnail: 'https://i.vimeocdn.com/video/823462964.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189399" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367189399,
					},
					{
						// name: "Prayer Stretch - Strong Lower Back & Hips, Relief Exercise",
						name: 'Prayer Stretch - Relief Exercise',
						url: 'https://vimeo.com/367189341/b761489886',
						thumbnail: 'https://i.vimeocdn.com/video/823462895.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189341" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 367189341,
					},						
				],
			},
			{
				playlist_name :'Strong Lower Back & Back - Strength Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/826476391.jpg',
				playlist_urls: [															
					{
						// name: "Superman - Strong Lower Back & Hips, Strength Exercise",
						name: 'Superman - Strength Exercise',
						url: 'https://vimeo.com/367189612/95cb91401f',
						thumbnail: 'https://i.vimeocdn.com/video/826476391.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189612" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367189612,
					},
					{
						// name: "Overhead Squat - Strong Lower Back & Hips, Strength Exercise",
						name: 'Overhead Squat - Strength Exercise',
						url: 'https://vimeo.com/367189759/81749739f9',
						thumbnail: 'https://i.vimeocdn.com/video/823463413.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189759" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367189759,
					},
					{
						// name: "Hip Roll - Strong Lower Back & Hips, Strength Exercise",
						name: 'Hip Roll - Strength Exercise',
						url: 'https://vimeo.com/367189724/53b4c4a16e',
						thumbnail: 'https://i.vimeocdn.com/video/823463373.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189724" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367189724,
					},
					{
						// name: "Prayer Plank - Strong Lower Back & Hips, Strength Exercise",
						name: 'Prayer Plank - Strength Exercise',
						url: 'https://vimeo.com/367189696/315215289e',
						thumbnail: 'https://i.vimeocdn.com/video/823463330.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189696" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367189696,
					},
					{
						// name: "Single Leg Bridge - Strong Lower Back & Hips, Strength Exercise",
						name: 'Single Leg Bridge - Strength Exercise',
						url: 'https://vimeo.com/367189663/e0fb6bb73d',
						thumbnail: 'https://i.vimeocdn.com/video/823463293.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/367189663" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 367189663,
					},
				],
			}
		]
		
	},
	{
		id: 6,
		test: [
			'calf-front-left',
			'calf-front-right',
			'calf-back-left',
			'calf-back-right',
			'knee-left',
			'knee-right',
			'quadriceps-left',
			'quadriceps-right',
			'it-band-left',
			'it-band-right',
			'hamstrings-left',
			'hamstrings-right',
		],
		playlists_name: 'Strong Knees & Calves Exercises',
		sub_playlists:[
			{
				playlist_name: 'Strong Knees & Calves - Relief Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823142981.jpg',
				playlist_urls: [	
					{
						// name: "Knee Extensions - Strong Knees & Calves, Relief Exercise",
						name: 'Knee Extensions - Relief Exercise',
						url: 'https://vimeo.com/366940082/ff4f4ee3dd',
						thumbnail: 'https://i.vimeocdn.com/video/823142981.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366940082" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 366940082,
					},
					{
						// name: "Wall squat - Strong Knees & Calves, Relief Exercise",
						name: 'Wall squat - Relief Exercise',
						url: 'https://vimeo.com/366942309/a082f5111e',
						thumbnail: 'https://i.vimeocdn.com/video/823148353.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366942309" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 366942309,
					},
					{
						// name: "Soleus Stretch - Strong Knees & Calves, Relief Exercise",
						name: 'Soleus Stretch - Relief Exercise',
						url: 'https://vimeo.com/366942034/1dbd89ea22',
						thumbnail: 'https://i.vimeocdn.com/video/823147685.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366942034" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 366942034,
					},
					{
						// name: "Gastrocnemius Stretch - Strong Knees & Calves, Relief Exercise",
						name: 'Gastrocnemius Stretch - Relief Exercise',
						url: 'https://vimeo.com/366941790/842031c7ff',
						thumbnail: 'https://i.vimeocdn.com/video/823149105.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366941790" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 366941790,
					},
					{
						// name: "Standing Knee Flexion - Strong Knees & Calves, Relief Exercise",
						name: 'Standing Knee Flexion - Relief Exercise',
						url: 'https://vimeo.com/366941589/3021d72847',
						thumbnail: 'https://i.vimeocdn.com/video/823146626.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366941589" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 366941589,
					},		
				],
			},
			{
				playlist_name: 'Strong Knees & Calves - Strength 1 Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823153293.jpg',
				playlist_urls: [		
					{
						// name: "Quad Set - Strong Knees & Calves, Strength 1 Exercise",
						name: 'Quad Set - Strength 1 Exercise',
						url: 'https://vimeo.com/366944355/e9e05348c1',
						thumbnail: 'https://i.vimeocdn.com/video/823153293.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366944355" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366944355,
					},
					{
						// name: "Prone Knee Flexion - Strong Knees & Calves, Strength 1 Exercise",
						name: 'Prone Knee Flexion - Strength 1 Exercise',
						url: 'https://vimeo.com/366946235/e108e3d3af',
						thumbnail: 'https://i.vimeocdn.com/video/823156262.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366946235" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366946235,
					},
					{
						// name: "Knee Flexion  - Strong Knees & Calves, Strength 1 Exercise",
						name: 'Knee Flexion  - Strength 1 Exercise',
						url: 'https://vimeo.com/366945169/68aa30fbcd',
						thumbnail: 'https://i.vimeocdn.com/video/823154849.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366945169" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366945169,
					},
					{
						// name: "Knee Clams - Strong Knees & Calves, Strength 1 Exercise",
						name: 'Knee Clams - Strength 1 Exercise',
						url: 'https://vimeo.com/366945053/ecfa8e421d',
						thumbnail: 'https://i.vimeocdn.com/video/823154700.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366945053" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366945053,
					},
					{
						// name: "Straight Leg Raise - Strong Knees & Calves, Strength 1 Exercise",
						name: 'Straight Leg Raise - Strength 1 Exercise',
						url: 'https://vimeo.com/366944900/f32417b653',
						thumbnail: 'https://i.vimeocdn.com/video/823154378.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366944900" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366944900,
					},
					{
						// name: "Short Arc Quad - Strong Knees & Calves, Strength 1 Exercise",
						name: 'Short Arc Quad - Strength 1 Exercise',
						url: 'https://vimeo.com/366944762/080f55f175',
						thumbnail: 'https://i.vimeocdn.com/video/823154019.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366944762" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366944762,
					},
						
				],
			},
			{
				playlist_name: 'Strong Knees & Calves - Strength 2 Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/823186225.jpg',
				playlist_urls: [												
					{
						// name: "Pelvic Raise - Strong Knees & Calves, Strength 2 Exercise",
						name: 'Pelvic Raise - Strength 2 Exercise',
						url: 'https://vimeo.com/366970591/ed807403d9',
						thumbnail: 'https://i.vimeocdn.com/video/823186225.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366970591" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366970591,
					},
					{
						// name: "Isometric Calf Squats - Strong Knees & Calves, Strength 2 Exercise",
						name: 'Isometric Calf Squats - Strength 2 Exercise',
						url: 'https://vimeo.com/366971498/598b160af5',
						thumbnail: 'https://i.vimeocdn.com/video/823187346.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366971498" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366971498,
					},
					{
						// name: "Lunges - Strong Knees & Calves, Strength 2 Exercise",
						name: 'Lunges - Strength 2 Exercise',
						url: 'https://vimeo.com/366971414/1a9e9f6cd7',
						thumbnail: 'https://i.vimeocdn.com/video/823187228.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366971414" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366971414,
					},
					{
						// name: "Squats At A Chair - Strong Knees & Calves, Strength 2 Exercise",
						name: 'Squats At A Chair - Strength 2 Exercise',
						url: 'https://vimeo.com/366971309/981cab389a',
						thumbnail: 'https://i.vimeocdn.com/video/823187077.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/366971309" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 366971309,
					},
				],
			}
		],
	
	},
	{
		id: 7,
		test: ['ankle-right', 'ankle-left', 'foot-left', 'foot-right', 'achilks-tendon-right', 'achilks-tendon-left'],
		playlists_name: 'Strong Ankles & Feet Exercises',
		sub_playlists: [
			{
				playlist_name : 'Strong Ankles & Feet, Relief Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/836256035.jpg',
				playlist_urls: [			
					{
						// name: "Seated Toe Taps - Strong Ankles & Feet, Relief Exercise",
						name: 'Seated Toe Taps - Relief Exercise',
						url: 'https://vimeo.com/376982675/1ee1588e21',
						thumbnail: 'https://i.vimeocdn.com/video/836256035.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376982675" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376982675,
					},
					{
						// name: "Calf Stretch - Strong Ankles & Feet, Relief Exercise",
						name: 'Calf Stretch - Relief Exercise',
						url: 'https://vimeo.com/376983008/721fa93849',
						thumbnail: 'https://i.vimeocdn.com/video/836256757.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376983008" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376983008,
					},
					{
						// name: "Four Directional Stretches - Strong Ankles & Feet, Relief Exercise",
						name: 'Four Directional Stretches - Relief Exercise',
						url: 'https://vimeo.com/376981887/ac0cc9b423',
						thumbnail: 'https://i.vimeocdn.com/video/836254930.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376981887" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376981887,
					},
					{
						// name: "Drawing Alphabets - Strong Ankles & Feet, Relief Exercise",
						name: 'Drawing Alphabets - Relief Exercise',
						url: 'https://vimeo.com/376982902/84d2bfe9ab',
						thumbnail: 'https://i.vimeocdn.com/video/836256162.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376982902" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376982902,
					},
					{
						// name: "Towel Scrunch - Strong Ankles & Feet, Relief Exercise",
						name: 'Towel Scrunch - Relief Exercise',
						url: 'https://vimeo.com/376981417/80aa34bb2f',
						thumbnail: 'https://i.vimeocdn.com/video/836254269.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376981417" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376981417,
					},		
				],
			},
			{
				playlist_name: 'Strong Ankles & Feet, Strength Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/836260081.jpg',
				playlist_urls: [		
					{
						// name: "Heel Raises - Strong Ankles & Feet, Strength Exercise",
						name: 'Heel Raises - Strength Exercise',
						url: 'https://vimeo.com/376985464/ad2b885d4a',
						thumbnail: 'https://i.vimeocdn.com/video/836260081.jpg',
						iframe: `<iframe src=""https://player.vimeo.com/video/376985464"" width=""640"" height=""360"" frameborder=""0"" allow=""autoplay; fullscreen"" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376985464,
					},
					{
						// name: "Y Balance - Strong Ankles & Feet, Strength Exercise",
						name: 'Y Balance - Strength Exercise',
						url: 'https://vimeo.com/376986124/8bb852ab65',
						thumbnail: 'https://i.vimeocdn.com/video/836260919.jpg',
						iframe: `<iframe src=""https://player.vimeo.com/video/376986124"" width=""640"" height=""360"" frameborder=""0"" allow=""autoplay; fullscreen"" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376986124,
					},
					{
						// name: "Side Lunges  - Strong Ankles & Feet, Strength Exercise",
						name: 'Side Lunges - Strength Exercise',
						url: 'https://vimeo.com/376986016/3647e6c956',
						thumbnail: 'https://i.vimeocdn.com/video/836260757.jpg',
						iframe: `<iframe src=""https://player.vimeo.com/video/376986016"" width=""640"" height=""360"" frameborder=""0"" allow=""autoplay; fullscreen"" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376986016,
					},
					{
						// name: "Plantar Fascia Stretches  - Strong Ankles & Feet, Strength Exercise",
						name: 'Plantar Fascia Stretches - Strength Exercise',
						url: 'https://vimeo.com/376985627/42491dd147',
						thumbnail: 'https://i.vimeocdn.com/video/836260245.jpg',
						iframe: `<iframe src=""https://player.vimeo.com/video/376985627"" width=""640"" height=""360"" frameborder=""0"" allow=""autoplay; fullscreen"" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376985627,
					},
				],
			}
		]
		
	},
	{
		id: 8,
		test: ['hand-left', 'hand-right'],
		region_name: 'Strong Fingers Exercises',
		sub_playlists: [
			{
				playlist_name: 'Strong Fingers, Relief Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/836251161.jpg',
				playlist_urls: [				
					{
						// name: "Combination of Finger Exercises - Strong Fingers, Relief Exercise",
						name: 'Combination of Finger Exercises - Relief Exercise',
						url: 'https://vimeo.com/376978969/423c1bc151',
						thumbnail: 'https://i.vimeocdn.com/video/836251161.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376978969" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376978969,
					},
					{
						// name: "Make A Fist - Strong Fingers, Relief Exercise",
						name: 'Make A Fist - Relief Exercise',
						url: 'https://vimeo.com/376980126/f62d291514',
						thumbnail: 'https://i.vimeocdn.com/video/836252545.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376980126" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376980126,
					},
					{
						// name: "Make An 'O' - Strong Fingers, Relief Exercise",
						name: "Make An 'O' - Relief Exercise",
						url: 'https://vimeo.com/376979519/920398a3b2',
						thumbnail: 'https://i.vimeocdn.com/video/836251469.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376979519" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376979519,
					},
					{
						// name: "Make A Claw - Strong Fingers, Relief Exercise",
						name: 'Make A Claw - Relief Exercise',
						url: 'https://vimeo.com/376979331/e56a664619',
						thumbnail: 'https://i.vimeocdn.com/video/836251374_480.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376979331" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.RELIEF,
						id: 376979331,
					},				
				],
			},
			{
				playlist_name: 'Strong Fingers, Strength Exercises',
				thumbnail: 'https://i.vimeocdn.com/video/836245081.jpg',
				playlist_urls: [		
					{
						// name: "Rubber Band Stretch - Strong Fingers, Relief Exercise ",
						name: 'Rubber Band Stretch - Strength Exercise ',
						url: 'https://vimeo.com/376974443/8bbf869f06',
						thumbnail: 'https://i.vimeocdn.com/video/836245081.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376974443" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376974443,
					},
					{
						// name: "Finger Squeeze - Strong Fingers, Relief Exercise",
						name: 'Finger Squeeze - Strength Exercise',
						url: 'https://vimeo.com/376975162/4c8dfdc604',
						thumbnail: 'https://i.vimeocdn.com/video/836246057.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376975162" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376975162,
					},
					{
						// name: "Putty Pinch Strength - Strong Fingers, Relief Exercise",
						name: 'Putty Pinch Strength - Strength Exercise',
						url: 'https://vimeo.com/376975091/8c15b760b1',
						thumbnail: 'https://i.vimeocdn.com/video/836245907.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376975091" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376975091,
					},
					{
						// name: "Putty Grasp & Squeeze - Strong Fingers, Relief Exercise",
						name: 'Putty Grasp & Squeeze - Strength Exercise',
						url: 'https://vimeo.com/376974911/8e280741ba',
						thumbnail: 'https://i.vimeocdn.com/video/836245735.jpg',
						iframe: `<iframe src="https://player.vimeo.com/video/376974911" frameborder="0" style="width: 100%; height: 100%" allow="autoplay; fullscreen" allowfullscreen></iframe>`,
						type: VIDEO_TYPE.STRENGTH,
						id: 376974911,
					},
				],

			}
		],
	
	},
];
