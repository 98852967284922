import React, { useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { useTooltipContext } from '../../context/tooltip';
import { useTouchOutside } from '../../hook/useTouchOutside';

const Container = styled.div`
	position: relative;
	display: inline-block;
`;

const TipImg = styled.img`
	object-fit: contain;
	width: 25px;
	margin-left: 5px;
	transform: translate(0px, 40%);
`;

export default (props) => {
	let { tooltipState, setTooltipState } = useTooltipContext();
	let { ref } = tooltipState;
	let imgRef = useRef();
	let [touchOutsideRef, isOutside] = useTouchOutside(true);

	useEffect(() => {
		setTooltipState({ show: false });
	}, [isOutside]);
	return (
		<Container ref={touchOutsideRef}>
			<TipImg
				onTouchStart={(e) => {
					e.stopPropagation();
					e.preventDefault();
					setTooltipState({
						show: !tooltipState.show,
						imgRef: e.target,
					});
				}}
				onMouseOver={(e) => {
					e.stopPropagation();
					e.preventDefault();
					setTooltipState({
						show: true,
						imgRef: e.target,
					});
				}}
				onMouseLeave={(e) => {
					e.stopPropagation();
					e.preventDefault();
					setTooltipState({ show: false });
				}}
				ref={imgRef}
				src={'https://i.imgur.com/tDRegeV.png'}
			></TipImg>
			{tooltipState.imgRef === imgRef.current && createPortal(props.children, ref)}
		</Container>
	);
};
