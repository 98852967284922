import React, { useState } from 'react';
import styled from 'styled-components';
import { BaseModal } from '../BaseModal';
import CANCEL from '../../img/cancel.svg';
import PainScale from '../../components/questionnaire_pain-scale';
import { LEVELS } from '../../constant';

const CloseIcon = styled.img`
	position: absolute;
	right: 20px;
	top: 20px;
	width: 10px;
	height: 10px;
	cursor: pointer;
`;

const Container = styled(BaseModal)`
	top: 50%;
	transform: translate(-50%, -50%);
	max-width: 100%;
	min-width: unset !important;
	min-height: unset !important;
	max-height: unset !important;
	width: max-content;

	padding: 30px;
`;

export default ({ onClose, questionnaireId, date, button, ...props }) => {
	let [levels, setLevels] = useState(LEVELS);
	return (
		<Container>
			<CloseIcon src={CANCEL} onClick={onClose}></CloseIcon>
			<PainScale
				style={{ width: '100%', marginTop: -50 }}
				levels={levels}
				setData={(newData) => {
					setLevels(newData.levels);
				}}
				title={() => 'Update pain level'}
				subtitle={(data) => `Level ${data.id}: ${data.name}`}
				button={{
					...button,
					props: {
						...button.props,
						onClick: () => button.props.onClick(questionnaireId, date, levels),
					},
				}}
			></PainScale>
		</Container>
	);
};
