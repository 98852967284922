import React, { useRef } from 'react';
import styled from 'styled-components';

import Button from '../predefined-components/button';
import { Container, Title as BaseTitle, Subtitle as BaseSubtitle } from '../questionnaire_welcome';
import { useModalContext } from '../../context/modal';
import { MODAL_CONSTANT } from '../../constant/modal';

const Title = styled(BaseTitle)`
	font-size: 32px;
	line-height: 38px;
	color: #272822;
`;

const Subtitle = styled(BaseSubtitle)`
	font-size: 18px;
	line-height: 21px;
	color: #4a4a4a;
	margin-top: 10px;
`;

const AreaContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	/* align-items: center; */
	flex-direction: column;

	@media screen and (min-width: 768px) {
		flex-direction: row;
	}

	margin: 20px 0 0 0px;
`;

const SVGContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		user-select: none;
		margin-top: 20px;
		color: #c4c4c4;
		font-size: 12px;
		line-height: 14px;
		font-family: 'Zilla Slab', serif;
	}

	/* margin: 0px 100px; */
`;

const AreaNames = styled.div`
	padding: 20px 0px;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 768px) {
		align-items: ${(props) => (props.dir === 'left' ? 'flex-start' : 'flex-end')};
	}

	p {
		color: #f2725a;
		font-size: 16px;
		line-height: 22px;
		font-family: 'Zilla Slab', serif;
	}
`;

const SVGContainerWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: 100%;

	${SVGContainer} {
		order: 1;
	}

	${AreaNames} {
		order: 2;
	}

	@media screen and (min-width: 425px) {
		max-width: 50%;
	}

	@media screen and (min-width: 768px) {
		flex-direction: row;

		${SVGContainer}, ${AreaNames} {
			order: unset;
		}
	}
`;

export default ({ nextStep, button, svg, Front, Back, setData, selectedAreas, ...props }) => {
	const FrontSVG = useRef(svg.front.Front);
	const BackSVG = useRef(svg.back.Back);

	const toggleAreas = (selectedAreas) => (e) => {
		const { questionnaireDirection: direction, questionnaireLr: lr, questionnaireName: name } = e.target.dataset;
		const regex = /([-\w]*)-hover/gm;
		const area = regex.exec(e.target.id)[1];
		const selected = selectedAreas.some((a) => a.id === area);
		if (selected) {
			setData({
				selectedAreas: selectedAreas.filter((a) => a.id !== area),
			});
		} else {
			setData({
				selectedAreas: selectedAreas.concat([{ id: area, name, direction, lr }]),
			});
		}
	};

	const { setModalState } = useModalContext();

	return (
		<Container>
			<Title>Highlight the area where you feel pain</Title>
			<Subtitle>You can choose more than one.</Subtitle>
			<AreaContainer>
				<SVGContainerWrapper>
					<AreaNames>
						{selectedAreas
							.filter((a) => a.direction === 'front')
							.map((a) => {
								return <p key={a.id}>{`${a.name} ${a.lr !== '' ? `(${a.lr === 'left' ? 'Left' : 'Right'})` : ''}`}</p>;
							})}
					</AreaNames>
					<SVGContainer>
						{FrontSVG.current && (
							<FrontSVG.current id={svg.front.id} selectedAreas={selectedAreas} toggleAreas={toggleAreas}></FrontSVG.current>
						)}
						<p>Front</p>
					</SVGContainer>
				</SVGContainerWrapper>
				<SVGContainerWrapper>
					<SVGContainer>
						{BackSVG.current && (
							<BackSVG.current id={svg.back.id} selectedAreas={selectedAreas} toggleAreas={toggleAreas}></BackSVG.current>
						)}
						<p>Back</p>
					</SVGContainer>
					<AreaNames dir="left">
						{selectedAreas
							.filter((a) => a.direction === 'back')
							.map((a) => {
								return <p key={a.id}>{`${a.name} ${a.lr !== '' ? `(${a.lr === 'left' ? 'Left' : 'Right'})` : ''}`}</p>;
							})}
					</AreaNames>
				</SVGContainerWrapper>
			</AreaContainer>
			<Button
				onClick={() => {
					if (selectedAreas.length === 0) {
						setModalState({
							isActive: true,
							type: MODAL_CONSTANT.TYPE_FULLPAGE,
							name: MODAL_CONSTANT.MODAL_GENERAL_INFO,
							data: {
								text: 'You must select at least 1 area.',
								button: {
									text: 'close',
								},
							},
						});
					} else {
						nextStep();
					}
				}}
				{...button.props}
			>
				{button.text}
			</Button>
		</Container>
	);
};
