import { getPainDuration, getSelectedArea, getSummarySelectedAreas, getPainDurationInSentence } from '../../utils';

export const generateEighthStepState = ({ data, onClickHandler }) => {
	return {
		step: 8,
		name: 'Questionnaire Recommendation',
		data: {
			subtitle: (data) => {
				const painDuration = getPainDuration(data);
				const selectedAreas = getSelectedArea(data);
				const summarySelectedAreas = getSummarySelectedAreas({ selectedAreas });
				const summaryPainDuration = getPainDurationInSentence(painDuration);
				
				return { summarySelectedAreas, summaryPainDuration };
			},
			button: {
				text: 'see my recovery kit',
				props: {
					id: 'see-my-recovery-kit',
					top: 50,
					onClick: () => {
						onClickHandler();
					},
				},
			},
		},
	};
};
