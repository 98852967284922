import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin-top: ${props =>
    props.top
      ? typeof props.top == "string"
        ? props.top
        : `${props.top}px`
      : "0px"};
  margin-bottom: ${props => (props.bottom ? `${props.bottom}px` : "0px")};
  margin-left: ${props => (props.left ? `${props.left}px` : "0px")};
  margin-right: ${props => (props.right ? `${props.right}px` : "0px")};

  width: ${props =>
    props.fullWidth ? "100%" : props.wrap ? "auto" : "max-content"};
  background: ${props =>
    props.variant === "link"
      ? "transparent"
      : props.variant === "secondary"
      ? props.color
      : props.bg || "#f2725a"};
  color: ${props =>
    props.variant === "link"
      ? "#f2725a"
      : props.variant === "secondary"
      ? props.bg
      : props.color || "white"};

  padding: ${props => (props.variant === "link" ? "unset" : "8px 19px")};

  text-transform: ${props => (props.disableUppercase ? "unset" : "uppercase")};
  max-width: 100%;

  text-overflow: ellipsis;
  border: ${props =>
    props.variant === "secondary"
      ? `2px solid ${props.bg}`
      : `2px solid transparent`};
  outline: none;
  font-weight: ${props => (props.variant === "link" ? 600 : 800)};
  font-size: 12px;
  line-height: 16px;
  letter-spacing: ${props => (props.variant === "link" ? "0px" : "1px")};

  font-style: ${props => (props.variant === "link" ? "italic" : "unset")};

  border-bottom: ${props =>
    props.variant === "link"
      ? `2px solid #f2725a`
      : props.variant === "secondary"
      ? `2px solid ${props.bg}`
      : "2px solid transparent"};

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    font-size: 12px;
  }

  @media screen and (min-width: 1024px) {
    width: ${props => (props.fullWidth ? "100%" : "max-content")};
    white-space: nowrap;
    font-size: 14px;
    padding: ${props => (props.variant === "link" ? "unset" : "13px 24px")};
  }

  text-decoration: none;

  transition: all 0.1s ease-in-out;

  :hover {
    background: ${props =>
      props.variant !== "link" && props.variant !== "secondary"
        ? "#d45f4a !important"
        : "transparent"};
  }

  :active {
    background: ${props =>
      props.variant !== "link" && props.variant !== "secondary"
        ? "#BF4D39 !important"
        : "transparent"};
  }
`;

export default props => {
  return (
    <Container as={props.variant === "link" ? "a" : "button"} {...props}>
      {props.children}
    </Container>
  );
};
