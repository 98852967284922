import React, { useEffect, Suspense } from 'react';
import styled from 'styled-components';
// import { Prompt } from 'react-router';

import Questionnaire from './components/questionnaire';
import { MODAL_CONSTANT } from './constant/modal';
import ModalController from './modal';
import { useModalContext } from './context/modal';
import { useUserContext } from './context/user';
import { useTooltipContext } from './context/tooltip';
import { Loading } from './components/Loading';

const Container = styled.div`
	position: relative;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;

	background: ${(props) => props.bg || '#faf7f5'};
`;

const FullPageModalWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	opacity: ${(props) => (props.active ? 1 : 0)};

	z-index: ${(props) => (props.active ? 1000 : -1)};

	transition: opacity 0.3s ease-in-out;
`;

export default ({ toolTipRef }) => {
	const { modalState } = useModalContext();
	const { setUserState } = useUserContext();
	const { setTooltipState } = useTooltipContext();

	useEffect(() => {
		if (toolTipRef && toolTipRef.current) {
			setTooltipState({ ref: toolTipRef.current });
		}

		setUserState({
			isChecked: true,
			isSignedIn: false,
			user: {},
			cartTotal: 0,
		});
	}, []);

	const shouldBlockNavigation = () => {
		return true;
	};

	return (
		<Suspense fallback={<Loading />}>
			<Container>
				<div style={{ position: 'relative' }}>
					<Questionnaire />
				</div>
				<FullPageModalWrapper active={modalState.isActive && modalState.type === MODAL_CONSTANT.TYPE_FULLPAGE} />
				<ModalController />
			</Container>
		</Suspense>
	);
};
