import React, { useEffect, useState, useRef, lazy } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';

import ProgressBar from '../questionnaire_progress-bar';
import { CartItem, CartItemContent, QtyChanger } from '../cart';
import QuestionnaireWelcome from '../questionnaire_welcome';
import QuestionnaireBodyPartSelection from '../questionnaire_body-part-selection';
import QuestionnaireHighlightArea from '../questionnaire_highlight-area';
import QuestionnairePainScale from '../questionnaire_pain-scale';
import QuestionnairePainDuration from '../questionnaire_pain-duration';
import QuestionnairePainCause from '../questionnaire_pain-cause';
import QuestionnaireGatherInfo from '../questionnaire_gather-info';
import QuestionnaireRecommendation from '../questionnaire_recommendation';
import QuestionnaireYourRoutine from '../questionnaire_your-routine';

import Button from '../predefined-components/button';
import { useUserContext } from '../../context/user';
import { VIDEO_PLAYLISTS } from '../../constant/VideoPlaylists';

import {
	generateFirstStepState,
	generateSecondStepState,
	generateThirdStepState,
	generateForthStepState,
	generateFifthStepState,
	generateSixthStepState,
	generateSeventhStepState,
	generateEighthStepState,
} from '../../constant';

import Tip from '../tip';
import { VideoThumbnail } from '../recovery-kit';
import {
	getPainDuration,
	getSelectedArea,
	getThirdRoutineRecovery,
	getUsedTime,
	fetchAllRecommendProducts,
	goToCartHandler,
	getRecommendItems,
	getBodyPart,
	getPainCause,
	getPainScale,
	getUserInfo,
	getSummarySelectedAreas,
	createOrderHandler,
} from '../../utils';
import { intersection } from 'lodash';
import { Loading } from '../Loading';
import { questionnaireServerAxiosInstance } from '../../utils/axios';

const ArmFront = lazy(() => import('../../components/questionnaire/arm-front'));
const ArmBack = lazy(() => import('../../components/questionnaire/arm-back'));
const LegFront = lazy(() => import('../../components/questionnaire/leg-front'));
const LegBack = lazy(() => import('../../components/questionnaire/leg-back'));
const LowerFront = lazy(() => import('../../components/questionnaire/lower-front'));
const LowerBack = lazy(() => import('../../components/questionnaire/lower-back'));
const UpperFront = lazy(() => import('../../components/questionnaire/upper-front'));
const UpperBack = lazy(() => import('../../components/questionnaire/upper-back'));

const INITIAL_RECOMMEND_ITEMS = [
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMzcxODM1NjgwNjU=',
		image: 'https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix_Product_GelTube_157-2.jpg?v=1611546938',
		quantity: 1,
		title: 'Relief Gel',
		variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzcwOTYwMzM3MzI0OQ==',
	},
	{
		id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYxMzcxNjg4ODgwMDE=',
		image: 'https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix_Product_PatchBox_116.jpg?v=1611546919',
		quantity: 1,
		title: 'Restore Patch',
		variantId: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzcwOTUxMzkxNjYwOQ==',
	},
];
const ACUTE_RECOVERY_KIT_ITEM = [
    {
        id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2OTUzMTMwODA1MTM=",
        name: "PainFix Acute Recovery Kit (2 Restore Patches and 2 Relief Gels)",
        price: 89.95,
        quantity: 1,
        thumbnail: "https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix-Product-RecoveryKit-1995_517db5a1-6152-46de-9511-9a9a6d6f24d5.jpg?v=1620314268",
        variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTg2NzQ1NzIwODUxMw=="
    }
];
const CHRONIC_RECOVERY_KIT_ITEM = [
    {
        id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2OTUzMTczMDc1ODU=",
        name: "PainFix Chronic Recovery Kit (4 Restore Patches and 2 Relief Gels)",
        price: 134.95,
        quantity: 1,
        thumbnail: "https://cdn.shopify.com/s/files/1/0518/9936/7617/products/Painfix-Product-RecoveryKit-1995_2abf113f-0dab-405f-98aa-6cfdb1415176.jpg?v=1620319805",
        variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zOTg2NzUxOTA3NDQ5Nw=="
    }
]
const videoData = [
	[
		{
			"name": "Strong Neck - Relief Exercises",
			"thumbnail": "https://i.vimeocdn.com/video/823454784.jpg",
			"playlist_urls": [
				{
					"name": "Strong Neck - Relief Exercises",
					"url": "https://vimeo.com/367174113/3699e435e4",
					"thumbnail": "https://i.vimeocdn.com/video/823443435.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174113\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367174113
				},
				{
					"name": "Strong Neck - Strength Exercises",
					"url": "https://vimeo.com/367183026/8cceb8c65f",
					"thumbnail": "https://i.vimeocdn.com/video/823454784.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183026\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367183026
				},
				{
					"name": "Neck Roll - Relief Exercises",
					"url": "https://vimeo.com/367174113/3699e435e4",
					"thumbnail": "https://i.vimeocdn.com/video/823443435.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174113\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174113
				},
				{
					"name": "Gravity Traction Neck Stretch - Relief Exercise",
					"url": "https://vimeo.com/376987906/0fd5e7c2a7",
					"thumbnail": "https://i.vimeocdn.com/video/836263529.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/376987906\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 376987906
				},
				{
					"name": "Chin to Shoulder Stretch - Relief Exercise",
					"url": "https://vimeo.com/367174466/dcd778d14d",
					"thumbnail": "https://i.vimeocdn.com/video/823443848.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174511\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174511
				},
				{
					"name": "Side Flexion - Relief Exercise",
					"url": "https://vimeo.com/367174113/3699e435e4",
					"thumbnail": "https://i.vimeocdn.com/video/823443502.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174466\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174466
				},
				{
					"name": "Chin to Chest and Back Stretch - Relief Exercise",
					"url": "https://vimeo.com/367174209/5ee03c29ec",
					"thumbnail": "https://i.vimeocdn.com/video/823443435.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174209\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174209
				},
				{
					"name": "Isometric Neck Strengthening - Strength Exercises",
					"url": "https://vimeo.com/367183026/8cceb8c65f",
					"thumbnail": "https://i.vimeocdn.com/video/823454784.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183026\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367183026
				},
				{
					"name": "Y-Prone Neck Strengthening - Strength Exercise",
					"url": "https://vimeo.com/367183098/e699d1a3d9",
					"thumbnail": "https://i.vimeocdn.com/video/823454927.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/379693599\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 379693599
				},
				{
					"name": "Isotonic Neck Strengthening - Strength Exercise",
					"url": "https://vimeo.com/379693599/8964277156",
					"thumbnail": "https://i.vimeocdn.com/video/839808670.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183098\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367183098
				}
			]
		},
		{
			"name": "Strong Neck - Strength Exercises",
			"thumbnail": "https://i.vimeocdn.com/video/823443435.jpg",
			"playlist_urls": [
				{
					"name": "Strong Neck - Relief Exercises",
					"url": "https://vimeo.com/367174113/3699e435e4",
					"thumbnail": "https://i.vimeocdn.com/video/823443435.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174113\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367174113
				},
				{
					"name": "Strong Neck - Strength Exercises",
					"url": "https://vimeo.com/367183026/8cceb8c65f",
					"thumbnail": "https://i.vimeocdn.com/video/823454784.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183026\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367183026
				},
				{
					"name": "Neck Roll - Relief Exercises",
					"url": "https://vimeo.com/367174113/3699e435e4",
					"thumbnail": "https://i.vimeocdn.com/video/823443435.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174113\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174113
				},
				{
					"name": "Gravity Traction Neck Stretch - Relief Exercise",
					"url": "https://vimeo.com/376987906/0fd5e7c2a7",
					"thumbnail": "https://i.vimeocdn.com/video/836263529.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/376987906\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 376987906
				},
				{
					"name": "Chin to Shoulder Stretch - Relief Exercise",
					"url": "https://vimeo.com/367174466/dcd778d14d",
					"thumbnail": "https://i.vimeocdn.com/video/823443848.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174511\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174511
				},
				{
					"name": "Side Flexion - Relief Exercise",
					"url": "https://vimeo.com/367174113/3699e435e4",
					"thumbnail": "https://i.vimeocdn.com/video/823443502.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174466\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174466
				},
				{
					"name": "Chin to Chest and Back Stretch - Relief Exercise",
					"url": "https://vimeo.com/367174209/5ee03c29ec",
					"thumbnail": "https://i.vimeocdn.com/video/823443435.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367174209\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367174209
				},
				{
					"name": "Isometric Neck Strengthening - Strength Exercises",
					"url": "https://vimeo.com/367183026/8cceb8c65f",
					"thumbnail": "https://i.vimeocdn.com/video/823454784.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183026\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367183026
				},
				{
					"name": "Y-Prone Neck Strengthening - Strength Exercise",
					"url": "https://vimeo.com/367183098/e699d1a3d9",
					"thumbnail": "https://i.vimeocdn.com/video/823454927.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/379693599\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 379693599
				},
				{
					"name": "Isotonic Neck Strengthening - Strength Exercise",
					"url": "https://vimeo.com/379693599/8964277156",
					"thumbnail": "https://i.vimeocdn.com/video/839808670.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183098\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367183098
				}
			]
		}
	],
	[
		{
			"name": "Strong Shoulders & Upper Back - Relief Exercises",
			"thumbnail": "https://i.vimeocdn.com/video/823455869.jpg",
			"playlist_urls": [
				{
					"name": "Strong Shoulders & Upper Back - Relief Exercises",
					"url": "https://vimeo.com/367183828/f5bda3d318",
					"thumbnail": "https://i.vimeocdn.com/video/823455869.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183828\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367183828
				},
				{
					"name": "Strong Shoulders & Upper Back - Strength 1 Exercises",
					"url": "https://vimeo.com/367185208/80c01aa606",
					"thumbnail": "https://i.vimeocdn.com/video/826826414.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185208\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367185208
				},
				{
					"name": "Strong Shoulders & Upper Back - Strength 2 Exercises",
					"url": "https://vimeo.com/367186136/8c2f9b7d94",
					"thumbnail": "https://i.vimeocdn.com/video/826826269.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186136\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367186136
				},
				{
					"name": "Shoulder Pendulum - Relief Exercise",
					"url": "https://vimeo.com/367183828/f5bda3d318",
					"thumbnail": "https://i.vimeocdn.com/video/823455869.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183828\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367183828
				},
				{
					"name": "Chest Expansion - Relief Exercise",
					"url": "https://vimeo.com/367184093/cdded4fe25",
					"thumbnail": "https://i.vimeocdn.com/video/823456176.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367184093\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367184093
				},
				{
					"name": "Shoulder Stretch - Relief Exercise",
					"url": "https://vimeo.com/367184003/2698a47665",
					"thumbnail": "https://i.vimeocdn.com/video/823456046.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367184003\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367184003
				},
				{
					"name": "Hand To Wall Stretch - Strength 1 Exercise",
					"url": "https://vimeo.com/367185208/80c01aa606",
					"thumbnail": "https://i.vimeocdn.com/video/826826414.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185208\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185208
				},
				{
					"name": "Shoulder Squeeze - Strength 1 Exercise",
					"url": "https://vimeo.com/367185950/c5a19c7770",
					"thumbnail": "https://i.vimeocdn.com/video/823458443.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185950\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185950
				},
				{
					"name": "Prone Push Half Body - Strength 1 Exercise",
					"url": "https://vimeo.com/367185896/94ec04581a",
					"thumbnail": "https://i.vimeocdn.com/video/823458363.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185896\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185896
				},
				{
					"name": "Prone Push Back - Strength 1 Exercise",
					"url": "https://vimeo.com/367185858/1ce0109ece",
					"thumbnail": "https://i.vimeocdn.com/video/823458341.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185858\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185858
				},
				{
					"name": "Sitting Tricep Stretch - Strength 1 Exercise",
					"url": "https://vimeo.com/367185808/d81d454033",
					"thumbnail": "https://i.vimeocdn.com/video/823458274.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185808\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185808
				},
				{
					"name": "Overhead Press - Strength 2 Exercise",
					"url": "https://vimeo.com/367186136/8c2f9b7d94",
					"thumbnail": "https://i.vimeocdn.com/video/826826269.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186136\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186136
				},
				{
					"name": "Shoulder Rowing - Strength 2 Exercise",
					"url": "https://vimeo.com/376987671/2fce771daf",
					"thumbnail": "https://i.vimeocdn.com/video/836262782.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/376987671\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 376987671
				},
				{
					"name": "Forward Bench Stretch - Strength 2 Exercise",
					"url": "https://vimeo.com/367186218/a43abf7141",
					"thumbnail": "https://i.vimeocdn.com/video/826826094.jpg",
					"ifarme": "<iframe src=\"https://player.vimeo.com/video/367186218\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186218
				},
				{
					"name": "Lateral Raise - Strength 2 Exercise",
					"url": "https://vimeo.com/367186186/72264e9ae5",
					"thumbnail": "https://i.vimeocdn.com/video/823458824.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186186\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186186
				},
				{
					"name": "Internal & External Shoulder Rotations - Strength 2 Exercise",
					"url": "https://vimeo.com/367185328/bfcb0e06b5",
					"thumbnail": "https://i.vimeocdn.com/video/823459099.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185328\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185328
				}
			]
		},
		{
			"name": "Strong Shoulders & Upper Back - Strength 1 Exercises",
			"thumbnail": "https://i.vimeocdn.com/video/826826414.jpg",
			"playlist_urls": [
				{
					"name": "Strong Shoulders & Upper Back - Relief Exercises",
					"url": "https://vimeo.com/367183828/f5bda3d318",
					"thumbnail": "https://i.vimeocdn.com/video/823455869.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183828\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367183828
				},
				{
					"name": "Strong Shoulders & Upper Back - Strength 1 Exercises",
					"url": "https://vimeo.com/367185208/80c01aa606",
					"thumbnail": "https://i.vimeocdn.com/video/826826414.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185208\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367185208
				},
				{
					"name": "Strong Shoulders & Upper Back - Strength 2 Exercises",
					"url": "https://vimeo.com/367186136/8c2f9b7d94",
					"thumbnail": "https://i.vimeocdn.com/video/826826269.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186136\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367186136
				},
				{
					"name": "Shoulder Pendulum - Relief Exercise",
					"url": "https://vimeo.com/367183828/f5bda3d318",
					"thumbnail": "https://i.vimeocdn.com/video/823455869.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183828\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367183828
				},
				{
					"name": "Chest Expansion - Relief Exercise",
					"url": "https://vimeo.com/367184093/cdded4fe25",
					"thumbnail": "https://i.vimeocdn.com/video/823456176.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367184093\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367184093
				},
				{
					"name": "Shoulder Stretch - Relief Exercise",
					"url": "https://vimeo.com/367184003/2698a47665",
					"thumbnail": "https://i.vimeocdn.com/video/823456046.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367184003\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367184003
				},
				{
					"name": "Hand To Wall Stretch - Strength 1 Exercise",
					"url": "https://vimeo.com/367185208/80c01aa606",
					"thumbnail": "https://i.vimeocdn.com/video/826826414.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185208\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185208
				},
				{
					"name": "Shoulder Squeeze - Strength 1 Exercise",
					"url": "https://vimeo.com/367185950/c5a19c7770",
					"thumbnail": "https://i.vimeocdn.com/video/823458443.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185950\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185950
				},
				{
					"name": "Prone Push Half Body - Strength 1 Exercise",
					"url": "https://vimeo.com/367185896/94ec04581a",
					"thumbnail": "https://i.vimeocdn.com/video/823458363.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185896\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185896
				},
				{
					"name": "Prone Push Back - Strength 1 Exercise",
					"url": "https://vimeo.com/367185858/1ce0109ece",
					"thumbnail": "https://i.vimeocdn.com/video/823458341.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185858\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185858
				},
				{
					"name": "Sitting Tricep Stretch - Strength 1 Exercise",
					"url": "https://vimeo.com/367185808/d81d454033",
					"thumbnail": "https://i.vimeocdn.com/video/823458274.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185808\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185808
				},
				{
					"name": "Overhead Press - Strength 2 Exercise",
					"url": "https://vimeo.com/367186136/8c2f9b7d94",
					"thumbnail": "https://i.vimeocdn.com/video/826826269.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186136\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186136
				},
				{
					"name": "Shoulder Rowing - Strength 2 Exercise",
					"url": "https://vimeo.com/376987671/2fce771daf",
					"thumbnail": "https://i.vimeocdn.com/video/836262782.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/376987671\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 376987671
				},
				{
					"name": "Forward Bench Stretch - Strength 2 Exercise",
					"url": "https://vimeo.com/367186218/a43abf7141",
					"thumbnail": "https://i.vimeocdn.com/video/826826094.jpg",
					"ifarme": "<iframe src=\"https://player.vimeo.com/video/367186218\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186218
				},
				{
					"name": "Lateral Raise - Strength 2 Exercise",
					"url": "https://vimeo.com/367186186/72264e9ae5",
					"thumbnail": "https://i.vimeocdn.com/video/823458824.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186186\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186186
				},
				{
					"name": "Internal & External Shoulder Rotations - Strength 2 Exercise",
					"url": "https://vimeo.com/367185328/bfcb0e06b5",
					"thumbnail": "https://i.vimeocdn.com/video/823459099.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185328\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185328
				}
			]
		},
		{
			"name": "Strong Shoulders & Upper Back - Strength 2 Exercises",
			"thumbnail": "https://i.vimeocdn.com/video/826826269.jpg",
			"playlist_urls": [
				{
					"name": "Strong Shoulders & Upper Back - Relief Exercises",
					"url": "https://vimeo.com/367183828/f5bda3d318",
					"thumbnail": "https://i.vimeocdn.com/video/823455869.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183828\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367183828
				},
				{
					"name": "Strong Shoulders & Upper Back - Strength 1 Exercises",
					"url": "https://vimeo.com/367185208/80c01aa606",
					"thumbnail": "https://i.vimeocdn.com/video/826826414.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185208\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367185208
				},
				{
					"name": "Strong Shoulders & Upper Back - Strength 2 Exercises",
					"url": "https://vimeo.com/367186136/8c2f9b7d94",
					"thumbnail": "https://i.vimeocdn.com/video/826826269.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186136\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"id": 367186136
				},
				{
					"name": "Shoulder Pendulum - Relief Exercise",
					"url": "https://vimeo.com/367183828/f5bda3d318",
					"thumbnail": "https://i.vimeocdn.com/video/823455869.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367183828\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367183828
				},
				{
					"name": "Chest Expansion - Relief Exercise",
					"url": "https://vimeo.com/367184093/cdded4fe25",
					"thumbnail": "https://i.vimeocdn.com/video/823456176.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367184093\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367184093
				},
				{
					"name": "Shoulder Stretch - Relief Exercise",
					"url": "https://vimeo.com/367184003/2698a47665",
					"thumbnail": "https://i.vimeocdn.com/video/823456046.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367184003\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "RELIEF",
					"id": 367184003
				},
				{
					"name": "Hand To Wall Stretch - Strength 1 Exercise",
					"url": "https://vimeo.com/367185208/80c01aa606",
					"thumbnail": "https://i.vimeocdn.com/video/826826414.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185208\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185208
				},
				{
					"name": "Shoulder Squeeze - Strength 1 Exercise",
					"url": "https://vimeo.com/367185950/c5a19c7770",
					"thumbnail": "https://i.vimeocdn.com/video/823458443.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185950\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185950
				},
				{
					"name": "Prone Push Half Body - Strength 1 Exercise",
					"url": "https://vimeo.com/367185896/94ec04581a",
					"thumbnail": "https://i.vimeocdn.com/video/823458363.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185896\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185896
				},
				{
					"name": "Prone Push Back - Strength 1 Exercise",
					"url": "https://vimeo.com/367185858/1ce0109ece",
					"thumbnail": "https://i.vimeocdn.com/video/823458341.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185858\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185858
				},
				{
					"name": "Sitting Tricep Stretch - Strength 1 Exercise",
					"url": "https://vimeo.com/367185808/d81d454033",
					"thumbnail": "https://i.vimeocdn.com/video/823458274.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185808\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185808
				},
				{
					"name": "Overhead Press - Strength 2 Exercise",
					"url": "https://vimeo.com/367186136/8c2f9b7d94",
					"thumbnail": "https://i.vimeocdn.com/video/826826269.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186136\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186136
				},
				{
					"name": "Shoulder Rowing - Strength 2 Exercise",
					"url": "https://vimeo.com/376987671/2fce771daf",
					"thumbnail": "https://i.vimeocdn.com/video/836262782.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/376987671\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 376987671
				},
				{
					"name": "Forward Bench Stretch - Strength 2 Exercise",
					"url": "https://vimeo.com/367186218/a43abf7141",
					"thumbnail": "https://i.vimeocdn.com/video/826826094.jpg",
					"ifarme": "<iframe src=\"https://player.vimeo.com/video/367186218\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186218
				},
				{
					"name": "Lateral Raise - Strength 2 Exercise",
					"url": "https://vimeo.com/367186186/72264e9ae5",
					"thumbnail": "https://i.vimeocdn.com/video/823458824.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367186186\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367186186
				},
				{
					"name": "Internal & External Shoulder Rotations - Strength 2 Exercise",
					"url": "https://vimeo.com/367185328/bfcb0e06b5",
					"thumbnail": "https://i.vimeocdn.com/video/823459099.jpg",
					"iframe": "<iframe src=\"https://player.vimeo.com/video/367185328\" frameborder=\"0\" style=\"width: 100%; height: 100%\" allow=\"autoplay; fullscreen\" allowfullscreen></iframe>",
					"type": "STRENGTH",
					"id": 367185328
				}
			]
		}
	]
]
const Container = styled.div`
	min-height: 100vh;
	width: 100vw;

	display: flex;
	flex-direction: column;

	padding: 15px;
	padding-bottom: 0px;

	@media screen and (min-width: 768px) {
		padding: 50px;
	}
`;

const Logo = styled.img`
	height: 100%;
	display: block;
	cursor: pointer;
`;

const RecommendImage = styled.img`
	height: 150px;
    cursor: pointer;
    width: 45%;
    margin-bottom: 1rem;
    margin-left: 2.5%;
`;

const Form = styled.form`
	margin-bottom: 30px;
`
const Header = styled.header`
	display: flex;
	justify-content: center;
	position: relative;
`;

const Body = styled.div`
	flex: 1;
`;

const Footer = styled.footer`
	padding: 20px 0px;
`;

const AdvisoryFooter = styled(Footer)`
	border-top: 1px solid #c8c0c0;
	color: #9a9a9a;
	text-align: center;
	font-size: 16px;
	line-height: 22px;

	padding: 20px 0px;

	font-family: 'Zilla Slab', serif;
`;

const BackButton = styled.div`
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	display: flex;
	align-items: center;
	img {
		display: inline-block;
	}

	:hover {
		span {
			color: #272822;
		}
	}

	span {
		color: #c4c4c4;
		font-weight: 500;
		text-transform: uppercase;
		font-size: 14px;

		transition: color 0.2s ease-in-out;
	}
`;
const FormProduct = styled.div`
	background: white;
    display: grid;
	margin-bottom:20px;
	grid-template-columns: 72px 1fr;
    grid-template-rows: minmax(72px,72px);
	img {
		width: 100%;
    	align-self: center;
	}
	div {
		grid-template-columns: 1fr max-content;
		grid-template-rows: max-content max-content;
		padding:0 10px;
		align-content: space-between;
		h1 {
			overflow-x: hidden;
			text-overflow: ellipsis;
			font-family: "Zilla Slab", serif;
			font-size: 18px;
			line-height: 22px;
			letter-spacing: 0.5px;
			color: rgb(39, 40, 34);
			font-weight: bold;
			align-self: flex-end;
			padding-bottom:0px;
		}
		div {
			display: unset;
			padding-left: 0;
			padding-right: 30px;
		}
		span {
			font-size: 14px;
			line-height: 24px;
			color: rgb(30, 30, 30);
			padding-top:10px;
		}
	}
`;

export default ({ setHideLayout, ...props }) => {
	const { userState, setUserState } = useUserContext();
	const [step, setStep] = useState({
		current: 1,
		previous: 1,
	});
	const [isLoading, setIsLoading] = useState(false);

    const [recommendItems, setRecommendItems] = useState(CHRONIC_RECOVERY_KIT_ITEM);

    const [recommendVideos, setRecommendVideos] = useState([]);
	
	const [isRecommendExisted, setIsRecommendExisted] = useState(false);

	const [subPlayListName,setSubPlayListName] = useState([]);

	const [subPlayListVideos,setSubPlayListVideos] = useState([]);

	const [testData,setTestData]= useState([]);
	
	const nextStep = () => {
		setStep((oldStep) => ({
			current: oldStep.current + 1,
			previous: oldStep.current,
		}));
	};

	const previousStep = () => {
		setStep((oldStep) => ({
			current: oldStep.current - 1,
			previous: oldStep.current,
		}));
	};

	const onChangeNameHandler = (data) => {
		setUserState((prevUser) => ({
			...prevUser,
			user: {
				...prevUser.user,
				username: data,
			},
		}));
	};

	const onChangeEmailHandler = (data) => {
		setUserState((prevUser) => ({
			...prevUser,
			user: {
				...prevUser.user,
				email: data,
			},
		}));
	};

	const isGuest = !localStorage.getItem('userId');

	const [data, setData] = useState([
		generateFirstStepState({ onClickHandler: nextStep }),
		generateSecondStepState({
			ArmFront,
			ArmBack,
			LegFront,
			LegBack,
			LowerFront,
			LowerBack,
			UpperFront,
			UpperBack,
		}),
		generateThirdStepState(),
		generateForthStepState({ onClickHandler: nextStep }),
		generateFifthStepState(),
		generateSixthStepState(),
		generateSeventhStepState({
			onChangeNameHandler,
			onChangeEmailHandler,
			name: !isGuest ? userState.user.firstName : '',
			email: !isGuest ? userState.user.username : '',
		}),
		generateEighthStepState({
			data,
			onClickHandler: nextStep,
		}),
		{
			step: 9,
			name: 'Questionnaire Your Routine',
			data: {
				columns: [
					{
						id: 1,
						title: (firstName) => {
							return `Your Recovery Program`;
						},
						bodyComponents: {
							Routine: styled.div`
								display: flex;
								flex-direction: column;

								:not(:first-of-type) {
									margin-top: 20px;
								}

								& > div {
									font-family: 'Zilla Slab', serif;
									color: #363940;
									font-size: 16px;
									line-height: 22px;
									text-align: center;

									:first-of-type {
										font-weight: 600;
									}
								}
							`,
							Tip,
						},
						footerComponents: {
							Container: styled.div`
								margin-top: 70px;

								p,
								a {
									font-size: 14px;
									line-height: 22px;
									color: #9b9b9b;
									text-align: center;
								}

								a {
									color: #f2725a;
									text-decoration: none;
								}
							`,
						},
						data: {
							routines: [
								{
									step: 1,
									message: () => 'Gently massage the Relief Gel in the tight area(s) of your body.',
									tip: 'Apply before and after activities that put stress on your affected area.',
								},							
								{
									step: 2,
									message: (painDuration, selectedAreas) => {
										return (
											<>
												Apply a new Restore Patch to your problem areas every morning or evening for 12 to 15 hours.
												We recommend that you apply a new Restore Patch to each problem area every day, and to use  all the patches
												provided in your kit each month for best results.
											</>
										);
									},
									tip: 'Pull on the ends of the patch to stretch it out a bit before applying for a better grip.',
								},
								{
									step: 3,
									message: () => 'Follow the recommended Strong Body exercises.',
									tip: 'Start with the Relief exercises first, then move to the Strength exercises once you feel ready to push your body.',
								},
								{
									step: 'Note',
									message: () => {
										return (
											<>
												Your targeted exercises will be delivered to your account page on our website
											</>
										);
									},
									tip:'Your targeted exercises will be delivered to your account page on our website.'
								},
							],
						},
						
						body: ({ ColumnBody, Routine, Tip, routines }, data) => {
							const painDuration = getPainDuration(data);						

							routines = [
								{
									step: 1,
									message: 'Gently massage the Relief Gel in the tight area(s) of your body.',
									tip: 'Apply before and after activities that put stress on your affected area.',
								},
								{
									step: 2,
									message: (
										<>
										Apply a new Restore Patch to your problem areas every morning or evening for 12 to 15 hours.
										<br></br>
										We recommend that you apply a new Restore Patch to each problem area every day, and to use all the patches
										provided in your kit each month for best results.
													
									</>
								
										
									),
									tip: 'Pull on the ends of the patch to stretch it out a bit before applying for a better grip.',
								},
								{
									step: 3,
									message: 'Follow the recommended Strong Body exercises.',
									tip: 'Start with the Relief exercises first, then move to the Strength exercises once you feel ready to push your body.',
								},
								
								
							];

							return (
								<ColumnBody style={{ marginTop: 30 }}>
									{routines.map((r) => {
										return (
											<Routine key={`routine-${r.step}`}>
												<div>{r.step}</div>
												<div>
													{r.message} <Tip>{r.tip}</Tip>
												</div>
											</Routine>
										);
									})}
									<Routine key={`routine-note`}>
												<div style={{ fontSize:'13px' }}>Note</div>
												<div style={{ fontSize:'13px' }}>
												Your targeted exercises will be delivered to your account page on our website. 
												<Tip>Your targeted exercises will be delivered to your account page on our website.</Tip>
												</div>
									</Routine>
								</ColumnBody>
							);
						},
						footer: ({ ColumnFooter, Container }) => {
							if (!isGuest) return null;
							return (
								<ColumnFooter>
									<Container></Container>
								</ColumnFooter>
							);
						},
					},
					{
						id: 2,
						title: () => {
							return `Your Cart`;
						},
						bodyComponents: {
							QtyChanger,
							CartItem,
							CartItemContent,
							Container: styled.div`
								display: flex;
								flex-direction: column;
								margin-top: 30px;
							`,
						},
						data: {
							products: [],
							videos: [],
						},
						body: ({ QtyChanger, ColumnBody, Container, CartItem, CartItemContent, products, videos, onProductQuantityChanged},data) => {
							const selectedAreas = getSelectedArea(data);
							const getExercises = (selectedAreas) => {
								let exercises = VIDEO_PLAYLISTS.filter((vp) => {
									return (
										intersection(
											vp.test,
											selectedAreas.map((sa) => sa.id)
										).length > 0
									);
								})
								return exercises;
								
							};
							const exercises = getExercises(selectedAreas)

							return (
								<ColumnBody>
									<Container>
									
										{products.map((p) => {
											return (
												<FormProduct>
												<img key={`questionnaire-recommended-product-${p.id}`} src={p.thumbnail} alt="questionnaire recommended product thumbnail" />
												<div>
												<h1>{p.name}</h1>
												<div>
												<QtyChanger
													productId= {p.id}
													v={p.quantity}
													min={0}
													max= {0}
													onChangeClick ={(newQuantity)=>{
														if(newQuantity >0) {
															onProductQuantityChanged(p.id,newQuantity)
														}
													}}
												></QtyChanger>
												</div>												
												<span>$ {(p.price*p.quantity).toFixed(2)} </span>												
												</div>																							
												</FormProduct>
											);
										})}
									
										{exercises.map((el) => {

												return el.sub_playlists.map (element => (
													<CartItem
														key={`questionnaire-recommended-video-${element.playlist_name}`}
														onClick={(e) => {
															return false;
														}}
													>
														<VideoThumbnail
															w={72}
															h={72}
															style={{
																display: 'flex',
															}}
														>
															<img
																style={{
																	objectFit: 'cover',
																}}
																src={element.thumbnail}
																alt="questionnaire recommended video thumbnail"
															/>
														</VideoThumbnail>
														<CartItemContent>
															<h1
																style={{
																	whiteSpace: 'pre-wrap',
																}}
															>
																{element.playlist_name}
															</h1>
														</CartItemContent>
													</CartItem>
												)
												);
											
										})}
									</Container>
								</ColumnBody>
							);
						},
						footerComponents: {
							Button,
						},
						footer: ({ ColumnFooter, Button, masterData }) => {
							const selectedAreas = getSelectedArea(masterData);
							const getExercises = (selectedAreas) => {
								let exercises = VIDEO_PLAYLISTS.filter((vp) => {
									return (
										intersection(
											vp.test,
											selectedAreas.map((sa) => sa.id)
										).length > 0
									);
								})
								return exercises;
								
							};
							const exercises = getExercises(selectedAreas)
							const dataExercises =  exercises.map(el=>{
								return el.sub_playlists.map(element=>({
									name:element.playlist_name,
									thumbnail: element.thumbnail,
									playlist_urls: element.playlist_urls
								}))
							})

							return (
								<ColumnFooter>
									<Button style={{ width:'226px' }}
										onClick={async () => {
											try {
												setIsLoading(true);
												goToCartHandler(masterData,dataExercises);
											} catch (error) {
												console.error('Error : ', error);
											} finally {
												setIsLoading(false);
											}
										}}
										top={30}
									>
										buy now
									</Button>
									<Button style={{ width:'226px' }}
										onClick={() => (window.parent.location.href = 'https://painfixbody.com/')}
										top={30}
									>
									return to homepage								
									</Button>
								</ColumnFooter>
							);
						},
					},
				],
			},
		},
	]);

	const Component = useRef();
	switch (step.current) {
		case 1:
			Component.current = QuestionnaireWelcome;
			break;
		case 2:
			Component.current = QuestionnaireBodyPartSelection;
			break;
		case 3:
			Component.current = QuestionnaireHighlightArea;
			break;
		case 4:
			Component.current = QuestionnairePainScale;
			break;
		case 5:
			Component.current = QuestionnairePainDuration;
			break;
		case 6:
			Component.current = QuestionnairePainCause;
			break;
		case 7:
			if (isGuest) {
				Component.current = QuestionnaireGatherInfo;
				break;
			} else {
				Component.current = null;
				break;
			}
		case 8:
			Component.current = QuestionnaireRecommendation;
			break;
		case 9:
			Component.current = QuestionnaireYourRoutine;
			break;
		default:
			break;
	}

	useEffect(() => {
		async function handleUnload() {
			const selectedAreas = getSelectedArea(data);

			let getInfoFunctions = [
				getBodyPart.bind(null, data, 'title'),
				getSummarySelectedAreas.bind(null, { selectedAreas }),
				getPainScale.bind(null, data, 'name'),
				getPainDuration.bind(null, data, 'title'),
				getPainCause.bind(null, data, 'title'),
				getUserInfo.bind(null, data),
			];
			if (step.previous < 7) {
				getInfoFunctions = getInfoFunctions.slice(0, step.previous - 1);
			}

			const length = getInfoFunctions.length;

			const result = [];

			for (let i = 0; i < length; i++) {
				const res = getInfoFunctions[i]();

				result.push(res);
			}

			await questionnaireServerAxiosInstance.post('/stat', {
				data: JSON.stringify(result),
			});
		}

		window.addEventListener('beforeunload', handleUnload);

		return () => {
			window.removeEventListener('beforeunload', handleUnload);
		};
	}, [step]);

	useEffect(() => {
		const fetchCheckout = async () => {
			try {
				if (step.current === 9) {								
						setIsLoading(true);
						const selectedAreas = getSelectedArea(data);
						const videos = VIDEO_PLAYLISTS.filter((vp) => {
							return (
								intersection(
									vp.test,
									selectedAreas.map((sa) => sa.id)
								).length > 0
							);
						}).reduce((acc, cur) => {						
							return acc.concat(cur.playlist_urls);
						}, []);
					
						const painDuration = getPainDuration(data);
							
						let Quantity;
						let response;

					  switch(painDuration.id) {
							case 1 :
								if(selectedAreas.length > 0 && selectedAreas.length <=3) {
									Quantity = 1;								   
								}
								else if(selectedAreas.length > 3 && selectedAreas.length <7) {
									Quantity = 1;
								}
								else if(selectedAreas.length >=7) {
									Quantity = 1;	
								}
								break;
							case 2 :
								if(selectedAreas.length > 0 && selectedAreas.length <=3) {
									Quantity = 1;	
								}
								else if(selectedAreas.length > 3 && selectedAreas.length <7) {
									Quantity = 2;	
								}
								else if(selectedAreas.length >=7) {
									Quantity = 3;
								}
								break;
							case 3 :
								if(selectedAreas.length > 0 && selectedAreas.length <=3) {
									Quantity = 1;	
								}
								else if(selectedAreas.length > 3 && selectedAreas.length <7) {
									Quantity = 2;	
								}
								else if(selectedAreas.length >=7) {
									Quantity = 3;
								}
								break;
						}
						if(painDuration.id===1 && selectedAreas.length > 0 && selectedAreas.length <=3) {
							response = await fetchAllRecommendProducts(ACUTE_RECOVERY_KIT_ITEM);				   
						}
						else {
						
							response = await fetchAllRecommendProducts(CHRONIC_RECOVERY_KIT_ITEM);				   
						}
						const products = response.map((item, index) => {
							return {
								id: item.id,
								name: item.title,
								quantity: Quantity,
								thumbnail: item.images[0].src,
								variantId: item.variants[0].id,
								price: item.variants[0].price ,
							};
						});
						const newMasterData = data.map((d) => {
							if (d.step === step.current) {
								let data = d.data;
								return {
									...d,
									data: {
										...data,
										columns: data.columns.map((c) => {
											if (c.id === 2) {
												let cData = c.data;
												return {
													...c,
													data: {
														...cData,
														products,
														videos,
													},
												};
											} else {
												return c;
											}
										}),
									},
								};
							} else {
								return d;
							}
						});
						setData(newMasterData);
						setIsLoading(false);
					
				} else if (step.current === 8) {
					setIsLoading(true);					
					setIsRecommendExisted(false);									
				} else if (step.current === 7) {
					if (!isGuest) {
						if (step.previous === 8) {
							setStep({ current: 6, previous: 8 });
						} else {
							setStep({ current: 8, previous: 6 });
						}
					}
				}
			} catch (error) {
				console.error('Error : ', error);
			} finally {
				setIsLoading(false);
			}
		};
		fetchCheckout();
	}, [step]);

	const setNewData = (newData) => {
		setData((oldState) => {
			let newState = oldState.map((s) => {
				let cloned = cloneDeep(s.data);
				Object.assign(cloned, newData);
				if (s.step === step.current) {
					return { ...s, data: cloned };
				} else {
					return s;
				}
			});
			return newState;
		});
	};

	const setNewDiffData = (newData) => {
		setData((oldState) => {
			let newState = oldState.map((s) => {
				let cloned = cloneDeep(s.data);
				Object.assign(cloned, newData.data);
				if (s.step === newData.step) {
					return { ...s, data: cloned };
				} else {
					return s;
				}
			});
			return newState;
		});
	};
	let test=[]
	for(let i in videoData){
		videoData[i].map(el=>{
		  return test = [
			  ...test,
			  el
		  ]
	   })
	 }

	return (
		<Container>
			<Header>
				{step.current >= 2 && step.current <= 9 && (
					<BackButton onClick={previousStep}>
						<img src="https://i.imgur.com/jZMOtoE.png" alt="questionnaire back button" />
						<span>back</span>
					</BackButton>
				)}
				<Logo
					onClick={() => (window.parent.location.href = 'https://painfixbody.com/')}
					src={'https://i.imgur.com/BDHoO3N.png'}
					alt="painfix logo"
				/>
			</Header>
			<Body>
				{isLoading ? (
					<Loading />
				) : (
					Component.current && (
						<Component.current
							{...(data.find((d) => d.step === step.current) ? data.find((d) => d.step === step.current).data : null)}
							masterData={data}
							setData={setNewData}
							setDiffData={setNewDiffData}
							nextStep={nextStep}
							previousStep={previousStep}
							recommendItems={step.current === 8 ? recommendItems : null}
						></Component.current>
					)
				)}
			</Body>
			<Footer>
				{step.current >= 2 && step.current <= (isGuest ? 7 : 6) ? (
					<ProgressBar steps={isGuest ? 6 : 5} step={step.current - 1}></ProgressBar>
				) : step.current >= 8 ? (
					<AdvisoryFooter as="div">
						As with any new health regimen, please consult your doctor or a medical professional before starting.
					</AdvisoryFooter>
				) : null}
			</Footer>
		</Container>
	);
};
