import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useTooltipContext } from '../../context/tooltip';
import { useSpring, animated } from 'react-spring';

const Tip = styled(animated.div)`
	::before {
		content: '';
		position: absolute;
		width: 0px;
		height: 0px;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		border-bottom: 7px solid #f2725a;
		top: 0;
		left: 10%;
		transform: translate(-50%, -100%);
	}

	/* opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (!props.hide ? 'visible' : 'hidden')}; */
	transform: translateX(-10%);
	/* transition: opacity 0.3s ease-in-out; */
	position: absolute;
	top: ${(props) => `${props.y}px`};
	left: ${(props) => `${props.x}px`};
	background: #f2725a;
	color: white;
	padding: 20px;
	z-index: 2;
	max-width: 300px;
	text-align: left;
`;

export default forwardRef((props, ref) => {
	let { tooltipState } = useTooltipContext();
	let { show, imgRef } = tooltipState;
	let { left, top, height, width } = imgRef ? imgRef.getBoundingClientRect() : { left: 0, top: 0, height: 0, width: 0 };
	let styles = useSpring({
		to: async (next) => {
			await next({
				opacity: show ? 1 : 0,
				visibility: 'visible',
			});
			await next({ visibility: show ? 'visible' : 'hidden' });
		},
		from: { opacity: 0, visibility: 'hidden' },
		config: {
			duration: 200,
			easing: (t) => (1 - Math.cos(Math.PI * t)) / 2,
		},
	});

	return (
		<Tip
			style={styles}
			// show={show}
			x={left + width / 2 + window.scrollX}
			y={top + window.scrollY + height + 10}
			ref={ref}
		></Tip>
	);
});
