import React, { createContext, useContext, useState } from 'react';
import { MODAL_CONSTANT } from '../constant/modal';

const ModalContext = createContext();

const initialModalState = {
	isActive: false,
	name: MODAL_CONSTANT.MODAL_NONE,
	type: MODAL_CONSTANT.TYPE_BODY,
	data: null,
	onClose: null,
	onSuccess: null,
	onError: null,
};

export default ({ children }) => {
	const [modalState, setModalState] = useState(initialModalState);

	return <ModalContext.Provider value={{ modalState, setModalState }}>{children}</ModalContext.Provider>;
};

export const useModalContext = () => useContext(ModalContext);
