import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const Container = styled.div`
	position: relative;
	width: 100%;
	margin: 0px 0px 20px;

	order: 1;

	@media screen and (min-width: 768px) {
		order: unset;
		max-width: 280px;
		margin: 0px 30px;
	}
`;

const Scale = styled.div`
	width: calc(100% - 6px);
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	height: 2px;
	background: #e8e4e1;
`;

const ScaleLimit = styled(Scale)`
	height: 6px;
	width: 6px;
	border-radius: 50%;
	transform-origin: left;
	background: ${(props) => (props.active ? '#58b4b5' : '#e8e4e1')};

	/* transform: ${(props) => `translate(${props.translateX}px, -50%)`}; */
	transform: translateY(-50%);
	left: ${(props) => `${props.translateX}px`};

	/* transition: all 0.5s ease; */

	cursor: pointer;
`;

const ScaleLimitWrapper = styled(ScaleLimit)`
	cursor: unset;
	position: absolute;
	height: 100vh;
`;

const ScaleActive = styled(Scale)`
	transform-origin: left;
	transform: ${(props) => `scaleX(${props.scaleX}) translateY(-50%)`};
	background: #58b4b5;

	transition: all 0.2s ease;
`;

const Notch = styled.img`
	transform: ${(props) => `translateX(calc(-50% + ${props.translateX}px)) scale(${props.scale})`};
	display: block;
	cursor: move;
	position: relative;

	transition: all 0.2s ease;
`;

const clamp = (num, clamp, higher) => (higher ? Math.min(Math.max(num, clamp), higher) : Math.min(num, clamp));

export default ({ levels, active, setScale, ...props }) => {
	const scaleRef = useRef();
	const [scaleWidth, setScaleWidth] = useState(0);
	const [levelsX, setLevelsX] = useState([]);
	const levelsRef = useRef([
		{
			id: 1,
			ref: useRef(),
		},
		{
			id: 2,
			ref: useRef(),
		},
		{
			id: 3,
			ref: useRef(),
		},
		{
			id: 4,
			ref: useRef(),
		},
		{
			id: 5,
			ref: useRef(),
		},
		{
			id: 6,
			ref: useRef(),
		},
		{
			id: 7,
			ref: useRef(),
		},
		{
			id: 8,
			ref: useRef(),
		},
		{
			id: 9,
			ref: useRef(),
		},
		{
			id: 10,
			ref: useRef(),
		},
	]);

	useEffect(() => {
		if (scaleRef.current) {
			setScaleWidth(scaleRef.current.getBoundingClientRect().width);
		}
	}, [scaleRef.current]);

	useEffect(() => {
		if (levelsRef.current) {
			const newLevelsX = levelsRef.current.map((lr) => {
				return {
					id: lr.id,
					x: lr.ref.current.getBoundingClientRect().x,
				};
			});
			setLevelsX(newLevelsX);
		}
	}, [...levelsRef.current.map((lr) => lr.ref.current)]);

	const onNotchMoved = (e) => {
		const touchX = clamp(e.touches[0].clientX, levelsX[0].x, levelsX[levelsX.length - 1].x);
		const newActiveLimitId = levelsX.filter((lx) => touchX >= lx.x).sort((lx1, lx2) => lx2.id - lx1.id)[0].id;
		if (newActiveLimitId === active.id) return;
		setScale(newActiveLimitId);
	};

	const onLimitDraggedOver = (e) => {
		const x = e.target.getBoundingClientRect().x;
		if( levelsX.find((lx) => x === lx.x).id !==undefined) {
			const newActiveLimitId = levelsX.find((lx) => x === lx.x).id;
			if (newActiveLimitId === active.id) return;
			setScale(newActiveLimitId);
		}
	};

	useEffect(() => {
		return () => {
			document.removeEventListener('touchmove', onNotchMoved);
			document.removeEventListener('dragover', onLimitDraggedOver);
		};
	}, []);

	return (
		<Container>
			<Scale ref={scaleRef}>
				{levels.map((l) => {
					return (
						<ScaleLimit
							ref={levelsRef.current ? levelsRef.current.find((lr) => lr.id === l.id).ref : null}
							id={`pain-scale-${l.id}`}
							key={`pain-scale-${l.id}`}
							translateX={scaleWidth * ((l.id - 1) / (levels.length - 1))}
						></ScaleLimit>
					);
				})}
			</Scale>
			<div style={{ position: 'relative', minWidth: 280 }}>
				<ScaleActive scaleX={(active.id - 1) / (levels.length - 1)}></ScaleActive>
				{levels.map((l) => {
					return (
						<ScaleLimitWrapper
							onDragOver={onLimitDraggedOver}
							key={`pain-scale-active-${l.id}`}
							translateX={scaleWidth * ((l.id - 1) / (levels.length - 1))}
							style={{
								opacity: l.id <= active.id ? 1 : 0,
								background: 'transparent',
							}}
						>
							<ScaleLimit onClick={() => setScale(l.id)} active={true}></ScaleLimit>
						</ScaleLimitWrapper>
					);
				})}
				<Notch
					onTouchMove={onNotchMoved}
					scale={(scaleWidth + 6) / 280}
					translateX={(scaleWidth + 6) * ((active.id - 1) / (levels.length - 1))}
					src={'https://i.imgur.com/794aKNB.png'}
					alt="pain scale notch"
				/>
			</div>
		</Container>
	);
};
