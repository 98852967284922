export const generateFifthStepState = () => ({
  step: 5,
  name: "Questionnaire Pain Duration",
  data: {
    cards: [
      {
        id: 1,
        title: "Less than 4 weeks",
        isSelected: false,
      },
      {
        id: 2,
        title: "4 to 12 weeks",
        isSelected: false,
      },
      {
        id: 3,
        title: "More than 12 weeks",
        isSelected: false,
      },
    ],
  },
});
