export const generateThirdStepState = () => ({
  step: 3,
  name: "Highlight Area",
  data: {
    button: {
      text: "select",
    },
    selectedAreas: [],
    svg: {
      front: null,
      back: null,
    },
  },
});
