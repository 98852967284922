import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Title, Subtitle, Container } from '../questionnaire_welcome';

const CustomTitle = styled(Title)`
	font-size: 36px;
	line-height: 43px;
	margin-top: 50px;
`;

const CustomSubtitle = styled(Subtitle)`
	margin-top: 10px;
`;

export const CardTitle = styled.p`
	font-size: 16px;
	line-height: 19px;
	color: #4a4a4a;
	font-weight: 500;
	font-family: 'Zilla Slab', serif;
	text-align: center;
	transition: all 0.2s ease-in-out;
`;

export const CardBody = styled(CardTitle)`
	font-weight: 300;
`;

export const Card = styled.div`
	background: white;

	padding: 15px;
	border: 1px solid #dfdfdf;
	max-width: 380px;
	cursor: pointer;

	:hover {
		background: #e8e4e1;
	}

	:active {
		background: #58b4b5;

		${CardTitle} {
			color: white;
		}

		${CardBody} {
			color: white;
		}
	}

	transition: all 0.2s ease-in-out;
	margin: 10px;
	width: 100%;

	@media screen and (min-width: 768px) {
		flex-basis: calc(50% - 20px);
	}
`;

export const Cards = styled.div`
	display: flex;
	margin: -10px;
	margin-top: 50px;
	flex-wrap: wrap;

	justify-content: center;

	max-width: calc(380px * 2 - 20px);
`;

export default ({ nextStep, cards, setData, setDiffData, ...props }) => {
	useEffect(() => {
		setDiffData({ step: 3, data: { selectedAreas: [] } });
	}, []);
	return (
		<Container>
			<CustomTitle>Where does it hurt?</CustomTitle>
			<CustomSubtitle>
				Choose one area to focus on first, you can add another after you
				complete this order if you have pain in more than one area.
			</CustomSubtitle>
			<Cards>
				{cards.map((c) => {
					return (
						<Card
							active={c.isSelected}
							onClick={() => {
								setData({
									cards: cards.map((oc) => {
										return {
											...oc,
											isSelected: oc.id === c.id,
										};
									}),
								});
								let { front, back } = c.svg;
								setDiffData({
									step: 3,
									data: { svg: { front, back } },
								});
								nextStep();
							}}
							key={`questionnaire-body-part-${c.title}-${c.id}`}
						>
							<CardTitle>{c.title}</CardTitle>
							<CardBody>{c.body}</CardBody>
						</Card>
					);
				})}
			</Cards>
		</Container>
	);
};
