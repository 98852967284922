import React from 'react';
import styled from 'styled-components';
import Button from '../../components/predefined-components/button';

export const Container = styled.div`
	display: flex;
	flex-direction: column;

	align-items: center;
`;

export const WelcomeImg = styled.img`
	display: block;
`;

export const Title = styled.p`
	margin-top: 20px;
	font-family: 'Zilla Slab', serif;
	font-size: 40px;

	line-height: 40px;
	color: #272822;
	text-align: center;

	max-width: 350px;

	@media screen and (min-width: 768px) {
		max-width: 460px;
	}
`;

export const Subtitle = styled(Title)`
	font-size: 18px;
	line-height: 21px;
`;

export default ({ button }) => {
	return (
		<Container>
			<WelcomeImg src="https://i.imgur.com/N2zAEWI.png"></WelcomeImg>
			<Title>Welcome to the PainFix questionnaire.</Title>
			<Subtitle>The answers to these questions will help us create a personalized Recovery Kit just for you.</Subtitle>
			<Button {...button.props} style={button.style}>
				{button.text}
			</Button>
		</Container>
	);
};
