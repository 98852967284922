import React from 'react';
import styled from 'styled-components';
import { Title, Container } from '../questionnaire_welcome';
import { Card as BaseCard, CardTitle } from '../questionnaire_pain-duration';
import { Cards as BaseCards } from '../questionnaire_body-part-selection';

const CustomTitle = styled(Title)`
	font-size: 32px;
	line-height: 38px;
`;

const Card = styled(BaseCard)`
	max-width: 380px;
`;

const Cards = styled(BaseCards)`
	max-width: calc(480px * 2 - 20px);
`;

export default ({ cards, setData, nextStep, data, ...props }) => {
	return (
		<Container>
			<CustomTitle>What's the cause of the pain?</CustomTitle>
			<Cards>
				{cards.map((c) => {
					return (
						<Card
							key={`questionnaire-pain-duration-${c.id}`}
							onClick={() => {
								setData({
									cards: cards.map((oc) => ({
										...oc,
										isSelected: oc.id === c.id,
									})),
								});
								nextStep();
							}}
						>
							<CardTitle>{c.title}</CardTitle>
						</Card>
					);
				})}
			</Cards>
		</Container>
	);
};
