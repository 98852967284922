import React from 'react';
import styled from 'styled-components';
import './index.css';

const Container = styled.div`
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #faf7f5;
`;

export const Loading = () => {
	return (
		<Container>
			<div className="loader">Loading...</div>
		</Container>
	);
};
